import { CommonHeader, DataResponse, CommonApiParams, CommonAbtestParams, fetchIntegratedDataFromNode, CommonParams } from 'fe-web-common';
import { AxiosResponse } from 'axios';
import { compareClienAndNodeData } from './compare';
import { getCommonHeader, getCommonAbtestParams, getCommonApiParams, SignleCommonParams } from './requestUtil';

/**
 * 获取聚合数据
 * 此方法为生产环境Web端获取与Node端数据比较的方法
 * @param apiPath {string} node服务上聚合数据的接口
 * @param requestBody {Q} 请求体
 * @param commonHeads {CommonHeader} 公共请求头
 * @param integrationFunc {(Q, CommonHeader) => R} 浏览器端获取聚合数据的方法
 * @returns
 */
export async function getIntegratedData<Q, R>(apiPath: string, requestBody: Q, commonHeads: CommonHeader, integrationFunc: (q: Q, commonHeads: CommonHeader) => Promise<R>): Promise<R> {
  // 验证阶段
  // 前端请求Java服务
  const result = await integrationFunc(requestBody, commonHeads);
  if (window.__CAN_USE_NODE_API__) {
    // !!! 深copy比较的原因是，老有人喜欢直接修改接口返回的数据！ 这点不可取！！！
    const source = JSON.parse(JSON.stringify(result));
    // 请求Node端接口获取数据
    fetchIntegratedDataFromNode(apiPath, requestBody, commonHeads).then((nodeResult: AxiosResponse<R, unknown>) => {
      // 比较上报数据
      try {
        const destination = JSON.parse(JSON.stringify(nodeResult.data));
        compareClienAndNodeData(apiPath, source, destination);
      } catch (err) { // 返回数据出现异常; 或者比较出现异常。
        // 这里需要上报下棱镜
        console.log(err);
      }
    }).catch((err) => {
      // 上报异常监控;
      console.log(err);
    });
  }
  return result;
}

/**
 * 获取聚合数据
 * 此方法为生产环境正式使用的方法
 * @param apiPath {string} node服务上聚合数据的接口
 * @param requestBody {Q} 请求体
 * @param commonHeads {CommonHeader} 公共请求头
 * @param integrationFunc {(Q, CommonHeader) => R} 浏览器端获取聚合数据的方法
 * @returns
 */
export async function realGetIntegratedData<Q, R>(apiPath: string, requestBody: Q, commonHeads: CommonHeader, integrationFunc: (q: Q, commonHeads: CommonHeader) => Promise<AxiosResponse<DataResponse<R>>>): Promise<AxiosResponse<DataResponse<R>>> {
  // 以下为应用生产阶段
  if (window.__CAN_USE_NODE_API__) {
    try {
      return await fetchIntegratedDataFromNode(apiPath, requestBody, commonHeads);
    } catch (err) {
      // 上报异常监控;
      console.log(err);
    }
  }
  const webResult = await integrationFunc(requestBody, commonHeads);
  return webResult;
}

/**
 * 通用服务处理
 * @param params {SignleCommonParams} 通用参数
 * @param otherParams {Q} 其他特殊参数
 * @param apiPath {string} node服务上聚合数据的接口
 * @param integrationFunc {(Q, CommonHeader) => R} 浏览器端获取聚合数据的方法
 * @returns
 */
export async function getIntegratedService<Q, R>(params: SignleCommonParams, otherParams: Q, apiPath: string, integrationFunc: (q: Q & CommonParams, commonHeads: CommonHeader) => Promise<R>) {
  const { uuid, isWeChatMiniApp, unionId, appId, channel, userIdencrypt, userId, userToken }: SignleCommonParams = params;
  const commonApiParams: CommonApiParams = getCommonApiParams({ uuid, isWeChatMiniApp, unionId, appId, channel });
  const commonAbTestParams: CommonAbtestParams = getCommonAbtestParams({ uuid, isWeChatMiniApp, channel, userIdencrypt });
  const commonHeader: CommonHeader = getCommonHeader({ userId, uuid, userToken });
  const integrationParams = {
    ...otherParams,
    commonApiParams,
    commonAbTestParams,
  };
  const result = await getIntegratedData<Q & CommonParams, R>(apiPath, integrationParams, commonHeader, integrationFunc);
  return result;
}
