export default {
  path: '/refund',
  routes: [
    {
      path: '/refund/detail',
      component: () => import('./Detail'),
      title: '退款详情',
    }, {
      path: '/refund/voucher',
      component: () => import('./Voucher'),
    }],
};
