/*
 * @Author: shixiaoxia
 * @Date: 2022-07-21 17:26:32
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-11-05 11:12:04
 * @Description: url处理方法
 */
import { url, storage } from 'common/utils';
import { transferSearchObj2Str, getLocationSearch, getHostFromUrl } from 'ybcommon/ybutils/common/common';
import { HOSTMAP, getSubDomain } from 'ybcommon/ybutils/common/company';
import { isWeMini } from 'utils/device';
import { getMediaUuid } from 'ybcommon/ybmodel/tgUtil';
import userStore from './UserStore';

export const updateChannel = (nextLocation, isRoute = true, noPush = false) => {
  const _nextLocation = isRoute ? nextLocation : url(nextLocation);
  const nextSearch = _nextLocation.search;
  // 将路径参数转成对象
  const nextSearchObj = getLocationSearch(nextSearch || '');
  // 路径参数统一添加channel，始终透传
  if (userStore.channel) nextSearchObj.channel = userStore.channel;
  const mediaUuid = getMediaUuid();
  if (mediaUuid) nextSearchObj.mediaUuid = mediaUuid;
  if (window.ybParams?.shuntId) {
    nextSearchObj.shuntId = window.ybParams?.shuntId;
  }
  const newSearchStr = transferSearchObj2Str(nextSearchObj);
  const newUrl = `${_nextLocation.origin || window.location.origin}${_nextLocation.pathname || window.__pathname}${newSearchStr}`;
  if (isRoute) {
    // 路由地址返回nexLocation,replace浏览器地址
    nextLocation.search = newSearchStr;
    // 对于会直接更新链接的情况，不更新浏览器路径，防止用户可以看到
    if (!noPush) {
      window.history.replaceState(window.history.state, window.location.title, newUrl);
    }
  } else {
    // 浏览器地址直接返回新的地址
    return newUrl;
  }
  return nextLocation;
};

/**
 * @name: 将链接中我们的域名替换成当前访问域名
 * @param {*} jumpUrl
 * @return {*}
 */
export const replaceHost = (jumpUrl) => {
  const { hostname } = getHostFromUrl(jumpUrl);
  // 没有host 无需替换
  if (!hostname) return jumpUrl;
  const subDomain = getSubDomain(hostname);
  let newUrl = jumpUrl;
  if (HOSTMAP[subDomain]) {
    // 我们的域名中包含此子域名，将域名替换为访问域名
    newUrl = newUrl.replace(/(https?:)?(\/\/)?([\w.\-:]+)(\/.*)?/, `${window.location.origin}$4`);
  }
  return newUrl;
};

/**
 * 处理banner链接里channel
 * @param {*} urlPath 要跳转的页面
 * @param {*} isReplace 是否为replace
 * @param {*} isJump 是否跳转
 * @param {*} needReplaceHost 是否需要替换域名（指定跳转域名时，无需替换）
 * @returns 转变后的url
 */
export const openHrefUrl = (urlPath, isReplace = false, isJump = true, needReplaceHost = true) => {
  let resultUrl = needReplaceHost ? replaceHost(urlPath) : urlPath;
  resultUrl = updateChannel(resultUrl, false);
  const container = document.scrollingElement;
  if (container) {
    const pre = container.scrollTop;
    storage.set('schoolY', pre);
  }
  if (isWeMini()) {
    // 小程序内跳转附带用户信息
    const { unionId, isWeChatMiniApp, appId } = userStore;
    if (resultUrl.indexOf('?') === -1) {
      // resultUrl = `${resultUrl}?userId=${userId}&unionId=${unionId}&userToken=${userToken}&isWeChatMiniApp=${isWeChatMiniApp}&appId=${appId}`;
      resultUrl = `${resultUrl}?&unionId=${unionId}&isWeChatMiniApp=${isWeChatMiniApp}&appId=${appId}`;
    } else {
      resultUrl = `${resultUrl}&unionId=${unionId}&isWeChatMiniApp=${isWeChatMiniApp}&appId=${appId}`;
    }
  }

  if (isJump) {
    // 是否跳转
    if (isReplace) {
      // 是否直接替换之前链接
      window.location.replace(resultUrl);
    } else {
      // 新打开链接
      window.location.href = resultUrl;
    }
  }
  return resultUrl;
};
