/* eslint-disable max-classes-per-file */
/*
 * @Author: shixiaoxia
 * @Date: 2022-10-25 14:47:01
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2022-10-26 17:43:54
 * @Description: 返回相关处理方法
 */
import { device } from 'fe-yb-tools';
import { isUndefined } from 'lodash-es';
import { useEffect } from 'react';

const { isBaidu, isHuaWei, isIOS, isQuark, isAndroid, isUC } = device;

// 不需要回退的特殊ua列表
const noNeedBackSpecialUARegExpList = [
  'PGZ110 Build/TP1A\\.220905\\.001.*HeyTapBrowser',
  'PHB110 Build/TP1A\\.220905\\.001.*HeyTapBrowser',
];
const noNeedBackSpecialUARegExpStr = noNeedBackSpecialUARegExpList.map((item) => `(${item})`).join('|');

// 是否是不需要回退的特殊ua
const isNoNeedBackSpecialUA = () => new RegExp(noNeedBackSpecialUARegExpStr, 'i').test(navigator?.userAgent);

const noNeedBack = (isBaidu() && (isHuaWei() || isIOS())) || (isQuark() && isAndroid()) || isUC() || isNoNeedBackSpecialUA();

let _canUseLocalBackListner = true;

export const isNoNeedBack = () => noNeedBack;

export const autoAddHistory = () => {
  let currentUrl = window.location.href;
  setTimeout(() => {
    try {
      if (isAndroid()) {
        if (isBaidu()) {
          // 百度不能push相同的路径
          window.history.replaceState(null, null, currentUrl);
          window.history.pushState(window.history.state, null, currentUrl);
        } else if (isHuaWei()) {
          window.history.pushState(window.history.state, null, currentUrl);
        } else {
          // 其他浏览器下可以通过hash区别路径
          const flag = currentUrl.lastIndexOf('#');
          if (flag !== -1) {
            currentUrl = currentUrl.substring(0, flag);
          }
          // window.history.replaceState(window.history.state, null, currentUrl);

          const newurl = `${currentUrl}#${(Math.random() * 100).toFixed(2)}`;
          window.history.pushState(null, null, newurl);
        }
      } else if (isUC()) {
        // 其他浏览器下可以通过hash区别路径
        const flag = currentUrl.lastIndexOf('#');
        if (flag !== -1) {
          currentUrl = currentUrl.substring(0, flag);
        }
        // window.history.replaceState(window.history.state, null, currentUrl);

        const newurl = `${currentUrl}#${(Math.random() * 100).toFixed(2)}`;
        window.history.pushState(null, null, newurl);
      } else {
        window.history.pushState(window.history.state, null, currentUrl);
      }
    } catch (error) {
      // @ts-ignore
      if (window.yb && window.yb.uplogCatchError) {
        // @ts-ignore
        window.yb.uplogCatchError(JSON.stringify({ code: 'historyError', msg: { message: error?.message || 'history异常', stack: error?.stack ? error.stack.split(':') : '' } }));
      }
    }
  }, 0);
};

export const autoBackHistory = () => {
  try {
    _canUseLocalBackListner = noNeedBack;
    if (!noNeedBack) {
      window.history.back();
    } // else { // 安卓夸克 百度app华为机型不处理后退 }
  } catch (err) {
    console.log(err);
  }
};

/**
 * 检查是否可以后退
 * @returns
 */
export const isCanUseLocalBackListener = () => _canUseLocalBackListner;

/**
 * 设置是否可以后端
 * @param { boolean } canUse
 */
export const setCanUseLocalBackListener = (canUse) => {
  _canUseLocalBackListner = canUse;
};

// todo: 移动到 global.d.ts 中
declare const __DEV__: boolean;

/**
 * 运行函数并捕获错误，通常用于运行调用者提供的回调函数
 * @param code 错误码
 * @param fn 待运行函数
 */
function captureExcpetion(code: string, fn: (...args: unknown[]) => unknown) {
  try {
    fn();
  } catch (error) {
    // 错误捕获
    // @ts-ignore
    if (window.yb && window.yb.uplogCatchError) {
      // @ts-ignore
      window.yb.uplogCatchError(JSON.stringify({ code, msg: { message: error?.message, stack: error?.stack ? error.stack.split(':') : '' } }));
    }
  }
}

function log(...args: unknown[]) {
  // eslint-disable-next-line no-console
  console.log(`${Date.now()} [BackController]`, ...args);
}

/**
 * Lock 的唯一标识符支持的类型。
 */
export type LockId = string | number | symbol;

/**
 * 加锁时可传入配置。
 */
export interface LockOptions {
  /**
   * 自定义唯一标识符。允许不传，不传时将自动分配一个 number 类型的标识符。
   */
  id?: string | symbol;
  /**
   * 用于开发者理解的锁名称。方便在 debug 等场景使用。
   */
  name?: string;
  /**
   * 释放计数器，当 navigate back 触发时减 1，为 0 时释放。允许传入 >= 1 的数字。如果希望创建一个只能主动释放的锁，可以传入 `Infinity`。
   *
   * 默认为 1，即一次 navigate back 触发后就释放该锁。
   *
   * @default 1
   */
  count?: number;
  /**
   * 多长时间后自动释放计数器，单位毫秒。传入 <= 0 的时间不会生效。默认为0 不会自动释放。
   */
  autoFree?: number;
  /**
   * 是否被动加路由。
   *
   * 1. 主动加路由：获取锁时加一层路由，主动释放锁时减一层路由。
   * 1. 被动加路由：获取锁时不加路由，主动释放锁时不减路由，当 navigate back 时加一层路由。
   *
   * 因为目前代码中存在大量的 popstate 监听，主动加路由/减路由容易引起更多的问题。
   *
   * 对于较为独立的逻辑，例如 1. 独立的弹窗展示逻辑（运行之前/运行之后 都没有其他的弹窗逻辑的情况下），更适合使用主动加路由的方式。
   *
   * @default false
   * @todo 被动加路由在部分浏览器上可能存在问题，这一点需要再确认。
   */
  passive?: boolean;
  /**
   * 当 navigate back 时如果锁被释放，则回调该函数。
   *
   * 主动调用 {@link BackController.free} 不会触发该回调
   */
  onFreeByNavigateBack?: () => void;
  /**
   * 当主动调用 {@link BackController.free} 函数释放锁，并且锁真正释放完成后，进行回调。
   *
   * 因为现在调用 {@link BackController.free} 和真正释放锁存在一定的延时，所以提供该回调用于监测非 navigate back 情况的释放。
   */
  onFree?: () => void;
}

/**
 * 加锁时返回的结果。
 */
export interface LockResult {
  /**
   * 锁的唯一标识符。
   */
  id: string | number | symbol;
  /**
   * 主动释放锁。等价于调用 {@link BackController.free}，只是无需传入 id。
   */
  free: () => void;
  /**
   * 清除自动释放定时器。
   */
  clearTimeout: () => void;
}

/**
 * 锁信息。
 */
export type LockProps = {
  /**
   * 唯一标识符。
   */
  id: LockId;
  /**
   * 用于开发者理解的锁名称。方便在 debug 等场景使用。
   */
  name?: string;
  /**
   * 释放计数器，当 navigate back 触发时减 1，为 0 时释放。
   */
  count: number;
  /**
   * 是否被动加路由
   */
  passive: boolean;
  /**
   * 主动释放 setTimeout 的句柄。
   */
  autoFreeHandler: number | null;
} & Pick<LockOptions, 'onFreeByNavigateBack' | 'onFree'>

/**
 * 锁。
 */
class Lock implements LockProps {
  id: LockId;

  name?: string;

  count: number;

  passive: boolean;

  autoFreeHandler: number | null;

  onFreeByNavigateBack?: LockOptions['onFreeByNavigateBack'];

  onFree?: LockOptions['onFree'];

  constructor(props: LockProps) {
    this.id = props.id;
    this.name = props.name;
    this.count = props.count;
    this.passive = props.passive;
    this.autoFreeHandler = props.autoFreeHandler;
    this.onFreeByNavigateBack = props.onFreeByNavigateBack;
    this.onFree = props.onFree;
  }

  toString() {
    const identifier: string[] = [`id=${String(this.id)}`];
    if (this.name !== undefined) {
      identifier.push(`name=${this.name}`);
    }
    return `Lock(${identifier.join(' ')})`;
  }
}

/**
 * 用于控制 navigation back 时的处理逻辑。
 *
 * 仅适用于 browser 环境。
 *
 * 例如在弹窗场景使用：
 * 1. 当弹窗展示时，调用 `const { free } = lock({ onFreeByNavigateBack: () => setModalVisible(false) })` 获取锁。
 * 1. 当弹窗关闭时，调用 `free()` 释放锁。
 * 1. 当用户 navigate back 时，会触发 onFreeByNavigateBack 回调将弹窗关闭。
 */
export class BackController {
  /** 唯一标识 */
  private name: string;

  /** 是否输出 debug 内容 */
  private debug = true;

  /**
   * 锁列表
   */
  private locks: Lock[] = [];

  /**
   * 等待销毁的锁列表。
   *
   * 锁进入待销毁列表，需要满足：
   * 1. 是主动加路由的锁
   * 1. 被主动释放
   * 1. 浏览器支持 navigate back
   *
   * 因为是主动加路由的锁，所以要减一层路由，将锁的真正释放过程放在这次减路由的过程中。
   */
  private pendingDestroyLocks: Lock[] = [];

  /** 自增 id */
  private id = 0;

  /** 副作用是否已运行 */
  private effectExecuted = false;

  /** 存储实例 */
  private static instance: Record<string, BackController | undefined> = {};

  constructor(name: string, debug = true) {
    this.name = name;
    this.debug = debug;
  }

  /**
   * 获取锁。
   *
   * 当 {@link options} 传入 passive = true 时（默认为 true），会加上一层路由，相应的，在主动释放锁时会减去一层路由。
   *
   * @param options 配置信息。
   * @returns 返回唯一标识 和 用于释放锁的 free 函数。
   */
  lock(options?: LockOptions): LockResult {
    const { id: optionId, name, count = 1, autoFree: optionAutoFree = 0, passive = false, onFreeByNavigateBack, onFree } = options ?? {};

    // 获取 id
    let id: string | number | symbol = optionId;
    if (id === undefined) {
      this.id += 1; // 自增
      id = this.id;
    }

    // 副作用未执行
    if (!this.effectExecuted) {
      throw new Error(`try lock id=${String(id)} name=${name}, but BackController(name=${this.name}) hasn't execute effect()`);
    }

    // 如果锁已经存在
    if (this.locks.find((lock) => lock.id === id)) {
      throw new Error(`lock id=${String(id)} name=${name} already exist`);
    }

    const lock: Lock = new Lock({ id, name, count: Math.max(count, 1), autoFreeHandler: null, passive, onFreeByNavigateBack, onFree });

    // 启动定时释放
    const autoFreeDuration = Math.max(optionAutoFree, 0);
    if (autoFreeDuration > 0) {
      lock.autoFreeHandler = window.setTimeout(() => {
        if (__DEV__) {
          log(`auto free ${lock}`);
        }
        this.free(id);
        lock.autoFreeHandler = null;
      }, autoFreeDuration);
    }

    if (__DEV__) {
      if (this.debug) {
        log(`lock ${lock}`);
      }
    }
    // 先加锁，再加路由
    this.locks.push(lock);
    if (!passive) {
      autoAddHistory();
    }

    return {
      id,
      free: () => this.free(id),
      clearTimeout: () => {
        this.clearTimeout(lock);
      },
    };
  }

  /**
   * 主动释放锁。无论此时锁的计数器是多少，都会释放锁。
   *
   * 每次主动释放锁时
   * 1. 如果有自动释放定时器，那么清理定时器。
   * 1. 如果是主动加路由的锁，那么此时会减去一层路由。
   *
   * 需要注意的是，目前为了和其他 popstate 时间回调竞争，锁的释放有一定的延迟，目前考虑是使用 setTimeout(fn, 0) 的方式
   *
   * @param id 锁唯一标识
   * @returns 是否释放成功，返回 true 时为释放成功，false 失败。
   */
  free(id: LockId): boolean {
    const targetLockPos = this.locks.findIndex((lock) => lock.id === id);
    if (targetLockPos >= 0) {
      const targetLock = this.locks[targetLockPos];
      if (__DEV__) {
        log(`will free ${targetLock}`);
      }
      this.clearTimeout(targetLock);

      if (targetLock.passive || noNeedBack) {
        // 如果是 passive === true，那么不减路由
        // 如果是 noNeedBack === true，即便是主动加路由的模式，也没法去减路由，那么就在这里将锁释放掉
        // 和 popstate handler 中的逻辑一致，也延迟释放
        setTimeout(() => {
          this.removeLockById(targetLock.id);
          if (__DEV__) {
            log(`did free ${targetLock} because of (passive == true || noNeedBack == true). passive is ${targetLock.passive}, noNeedBack is ${noNeedBack}`);
          }
          captureExcpetion('BackControllerOnFreeError', () => targetLock.onFree?.());
        }, 0);
      } else if (!noNeedBack) {
        // 主动加路由方式

        // 从 locks 释放锁
        this.locks.splice(targetLockPos, 1);
        // 可以使用 navigate back 的情况下：将锁放入待删除列表中，由 popstate 进行触发并释放
        this.pendingDestroyLocks.unshift(targetLock);
        autoBackHistory();
      } else {
        // 如果不允许使用 navigate back，那就无需处理，不减路由，为了和 navigate back 方式一致，延迟释放
        setTimeout(() => {
          this.removeLockById(targetLock.id);
          captureExcpetion('BackControllerOnFreeError', () => targetLock.onFree?.());
        }, 0);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (__DEV__) {
        log(`will free Lock(id=${String(id)}) but it cann't be found. do nothing`);
      }
    }
    return targetLockPos >= 0;
  }

  /**
   * 当前是否有锁。
   */
  isLocked() {
    if (__DEV__) {
      log(`isLocked ${this.pendingDestroyLocks.length > 0 || this.locks.length > 0}`);
    }
    return this.pendingDestroyLocks.length > 0 || this.locks.length > 0;
  }

  /**
   * 开始副作用。当不再需要时记得清理副作用。
   *
   * 目前开始副作用时，会主动加一层路由，这样当 navigate back 时（并且要求用户有交互），popstate 事件可以被监听到，如果不加这层路由，并且当前只有被动锁的情况下，navigate back 时可能会更换 url，导致本页面的代码无法正常执行和拦截。
   *
   * 需要注意的时，这层路由在 cleanEffect 中目前是不减的，因为暂时不确定释放是否会有较大的影响。
   * @todo 等之后所有的 popstate 都被收敛到 BackController 之后，考虑在 cleanEffect 中减去路由。
   *
   * 另外需要注意的是：
   *
   * 期望同一时间，只会有一次 effect 的调用，例如每个链路应该只有一个监听者，目的是期望避免同时有多个 popstate 事件的消费者。
   */
  effect() {
    if (__DEV__) {
      log(`effect name=${this.name}`);
    }
    if (this.effectExecuted) {
      this.cleanEffect();
    }

    window.addEventListener('popstate', this.handlePopState);
    // bugfix: 目前添加这层路由会有问题，出现路由不正确的情况，因此这层路由暂时不加了
    // 主动加一层路由
    // autoAddHistory();
    this.effectExecuted = true;
  }

  /**
   * 清理副作用。
   */
  cleanEffect() {
    if (__DEV__) {
      log(`cleanEffect name=${this.name}`);
    }
    window.removeEventListener('popstate', this.handlePopState);
    this.effectExecuted = false;
  }

  /**
   * 获取实例。
   * @param name 实例唯一标识
   */
  static getInstance(name: string): BackController {
    let result: BackController | undefined = this.instance[name];
    if (!result) {
      this.instance[name] = new BackController(name);
      result = this.instance[name];
    }
    return result;
  }

  /**
   * 销毁实例。
   * @param name 待销毁实例名称，如果不传则销毁全部实例。
   */
  static destroyInstance(name?: string) {
    if (isUndefined(name)) {
      Object.keys(this.instance).forEach(BackController.destroyInstance);
      return;
    }

    const instance = BackController.instance[name];
    if (instance) {
      instance.cleanEffect();
      BackController.instance[name] = undefined;
      Reflect.deleteProperty(BackController.instance, name);
    }
  }

  /**
   * 是否有 BackController 实例正在锁定中。
   */
  static isLocked() {
    return Object.values(BackController.instance).some((controller) => controller.isLocked());
  }

  /**
   * popstate 事件回调函数
   */
  private handlePopState = () => {
    if (__DEV__) {
      log(`popstate handler name=${this.name}`);
    }
    // 不需要处理
    if (!this.isLocked()) {
      return;
    }

    // 优先释放待销毁的锁
    if (this.pendingDestroyLocks.length > 0) {
      if (__DEV__) {
        log(`popstate handler name=${this.name} will free pending destroy lock`);
      }
      // 延迟释放，避免和其他的 popstate 出现竞争问题
      setTimeout(() => {
        const destoryedLock = this.pendingDestroyLocks.pop();
        if (__DEV__) {
          log(`popstate handler name=${this.name} did free pending destroy lock ${destoryedLock}`);
        }
        captureExcpetion('BackControllerOnFreeError', () => destoryedLock.onFree?.());
      }, 0);
    } else {
      const lock = this.locks[this.locks.length - 1];
      if (lock.count === 1) {
        if (__DEV__) {
          log(`popstate handler name=${this.name} will free lock ${lock}`);
        }
        this.clearTimeout(lock);
        // 弹栈并释放，延迟释放，避免和其他的 popstate 出现竞争问题
        setTimeout(() => {
          this.removeLockById(lock.id);
          if (__DEV__) {
            log(`popstate handler name=${this.name} did free lock ${lock}`);
          }
          captureExcpetion('BackControllerOnFreeByNavError', () => lock.onFreeByNavigateBack?.());
        }, 0);

        // 被动加上路由
        if (lock.passive) {
          autoAddHistory();
        }
      } else {
        if (__DEV__) {
          log(`popstate handler name=${this.name} ${lock} count--`);
        }
        // 不允许释放，所以再次增加路由
        lock.count -= 1;
        autoAddHistory();
      }
    }
  };

  /**
   * 清理自动释放定时器
   * @param lock 待释放的锁
   */
  private clearTimeout(lock: Lock) {
    if (lock.autoFreeHandler !== null) {
      window.clearTimeout(lock.autoFreeHandler);
      lock.autoFreeHandler = null;
    }
  }

  /**
   * 从列表中删除锁
   * @param id 锁id
   */
  private removeLockById(id: LockId) {
    const pos = this.locks.findIndex((lock) => lock.id === id);
    if (pos >= 0) {
      return this.locks.splice(pos, 1)[0];
    }

    return undefined;
  }
}

/**
 * 用于获取/释放锁的回调。内部使用 useEffect 实现。
 *
 * @todo 暂未实现
 */
export const useBackLockEffect = () => {
  //
};

/**
 * 触发/清理 BackController 的副作用。内部使用 useEffect 实现。
 * @param controller 需要处理的 BackController
 */
export const useBackControllerEffect = (controller: BackController) => {
  useEffect(() => {
    controller.effect();

    return () => {
      controller.cleanEffect();
    };
  }, [controller]);
};
