export const FACEID_INFO = 'faceID_info'; // 人脸识别缓存key

// 邮政绑卡失败视频&封面
export const POSTVIDEOMATERIAL = {
  postVideo: 'https://cdn.ybaobx.com/media/20250113/173673486112379B967.mp4',
  postVideoPoster: 'https://cdn.ybaobx.com/shunt/config/8428677034379128832.png?size=580*1080',
};

// 公司主体枚举
export const COMPANYMAP = {
  CN: 'yuanbao', // 经济
  SX: 'shouxin', // 首信
};

// groupId和主体映射
export const GROUPIDCOMPANYMAP = {
  1: 'yuanbao', // 经济
  2: 'shouxin', // 首信
};

// tenantCode和主体映射
export enum TENANTCODE {
  yuanbao = 1, // 经济
  shouxin = 10, // 首信
}

// 组合产品类型枚举
export const LINKTYPEMAP = {
  other: 0, // 非组合
  combine: 1, // 0+长组合产品
  couple: 2, // 均分组合产品
  compose: 3, // 0+12组合产品
};
