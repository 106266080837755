// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iconDui_ArxnGfDn {\n  width: 0.48rem;\n  height: 0.49333rem;\n  position: absolute;\n  bottom: -1px;\n  right: -1px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconDui": "iconDui_ArxnGfDn"
};
export default ___CSS_LOADER_EXPORT___;
