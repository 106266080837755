/*
 * @Author: cuilinchao
 * @Date: 2025-01-15 22:14:18
 * @LastEditors: cuilinchao
 * @LastEditTime: 2025-01-15 22:17:30
 * @Description: 引加微页面配置
 */

export default [
  {
    path: '/wecom/detail',
    component: () => import('./detail'),
  },
  {
    path: '/wecom/preview',
    component: () => import('./preview'),
  },
  {
    path: '/wecom/public',
    component: () => import('./public'),
  },
];
