import { AxiosResponse } from 'axios';
import { CommonHeader } from '../../base';
import { CommonApiParams } from '../../../types';
import { QueryUserInfoReq } from './QueryUserInfoReq';
import { bffFetch } from '../bffFetch';
/**
 * 查询跨主体用户信息
 * @param params /api/compliance/self-sync/query 特有参数
 * @param commonApiParams 公共参数
 * @param commonHeader 通用header
 * @returns 查询用户夸主体信息结果
 */
export async function queryUserInfo(
  params: QueryUserInfoReq,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
): Promise<AxiosResponse> {
  const { ...queryData } = params;
  const data = { ...commonApiParams, ...queryData };
  const result = await bffFetch(commonHeader).post('api/mall/bff/compliance/self-sync/query', data);
  return result;
}
