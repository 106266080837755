import classNames from 'classnames';
import React, { Component } from 'react';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import styles from './index.less';

export default class TagChecks extends Component {
  selectEl = null; // 选中的dom元素

  componentDidUpdate(preProps) {
    const { value } = this.props;
    if (preProps.value === value) return;
    const { initActiveElPos } = this.props;
    if (initActiveElPos) initActiveElPos(this.selectEl);
  }

  _renderItem = (val, index) => {
    const {
      tags,
      disabled = false,
      value,
      onTagChange,
      showProLabel,
      showProLabelZx = {},
      proLabelSrc = require('./img/proLabel.png'),
      actItemClass,
      disactItemClass,
      actSubTextClass,
      disactSubTextClass,
      actSubTextClass1,
      disactSubTextClass1,
      actSubTextClass2,
      disactSubTextClass2,
      tagClass,
      gap,
      hasLabel,
      labelSrc = require('./img/label.png'),
      idPrefix,
      doTips,
      iconSrc = '',
      type,
      icon,
      showSelectIcon = false, // 是否显示右下角的选中图标
      widthFix,
      selectIconPosition = 1, // 选中icon位置 1-右下，2-右上
      relationStyleType,
      proLabelClassName,
      tagTextClassName, // tag的内容文案样式
      extraSelectedIcon, // 外部传入的角标样式
    } = this.props;
    const _id = val.id || val.id === 0 ? val.id : val.value;
    let itemClassName = _id === value ? actItemClass : disactItemClass;
    if ((_id === 1 && showProLabel) || showProLabelZx[index]) {
      itemClassName = actItemClass;
    }
    const subTextClassName = _id === value ? actSubTextClass : disactSubTextClass;
    const subTextClassName1 = _id === value ? actSubTextClass1 : disactSubTextClass1;
    const subTextClassName2 = _id === value ? actSubTextClass2 : disactSubTextClass2;
    return (
      <div
        id={idPrefix ? `${idPrefix}${index}` : ''}
        key={`${index + 1}`}
        className={classNames('relItem', styles.itemContainer, itemClassName, { [proLabelClassName]: (_id === 1 && showProLabel) })}
        style={{
          flex: tags.length < 5 && !widthFix ? 1 : 'unset',
          marginLeft: index === 0 || index === 4 ? 0 : `${pxTOrem(gap)}rem`,
          opacity: disabled || (_id === 1 && showProLabel) || showProLabelZx[index] ? 0.37 : 1,
        }}
        onClick={() => {
          if (disabled) {
            return;
          }
          if (onTagChange && (_id !== value || type === 'single')) {
            if ((_id === 1 && showProLabel) || showProLabelZx[index]) {
              if (doTips) {
                doTips(_id);
              }
            } else {
              onTagChange(type === 'single' ? value : _id, val);
            }
          }
        }}
        ref={(el) => {
          if (_id === value && el) {
            const { initActiveElPos } = this.props;
            if (!this.selectEl && initActiveElPos) initActiveElPos(el);
            this.selectEl = el;
          }
        }}
      >
        {val.borderImg && val.borderImgStyle && <img src={val.borderImg} className={val.borderImgStyle} alt=" " />}
        <p className={styles.text}>
          <span
            className={tagTextClassName}
            style={{
              color: (_id === 1 && showProLabel) || showProLabelZx[index] ? '#fd7013' : '',
            }}
          >
            {val.text}
          </span>
          {val.subText ? <br /> : null}
          {val.subText ? <span className={subTextClassName ? `${styles.subText} ${subTextClassName}` : styles.subText}>{val.subText}</span> : null}
          {val.subText1 ? <span className={subTextClassName1 ? `${styles.subText} ${subTextClassName1}` : styles.subText}>{val.subText1}</span> : null}
          {val.subText2 ? <span className={subTextClassName2 ? `${styles.subText} ${subTextClassName2}` : styles.subText}>{val.subText2}</span> : null}
        </p>
        {value === _id && hasLabel ? <img src={labelSrc} className={tagClass || styles.taglabel} alt="" /> : null}
        {(_id === 1 && showProLabel) || showProLabelZx[index] ? <img src={proLabelSrc} className={styles.prolabel} alt="" /> : null}
        {_id === value && iconSrc ? <img src={iconSrc} className={icon ? styles.iconLabel1 : styles.iconLabel} alt="" /> : null}
        {_id === value && showSelectIcon ? <img src={selectIconPosition === 2 ? require('common/img/clickIconRT.png') : require('./img/clickIcon.png')} alt="" className={classNames(selectIconPosition === 2 ? styles.clickIconRT : styles.clickIcon, relationStyleType === 2 ? styles.version2RT : null)} /> : null}
        {_id === value && extraSelectedIcon ? extraSelectedIcon : null}
      </div>
    );
  };

  // 额外的占位选项
  _renderItemExtra = (val, index) => {
    const { gap } = this.props;
    return (
      <div
        style={{
          marginLeft: `${pxTOrem(gap)}rem`,
        }}
        key={`extra_${index + 1}`}
        className={styles.itemContainer2}
      />
    );
  };

  render() {
    const { tags, conClassName, extraTag = null } = this.props;
    return (
      <div className={conClassName ? `${styles.tagContainer} ${conClassName}` : styles.tagContainer}>
        {tags.map((val, index) => this._renderItem(val, index))}
        {extraTag && extraTag.map((val, index) => this._renderItemExtra(val, index))}
      </div>
    );
  }
}
