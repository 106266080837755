import { AxiosResponse } from 'axios';

import { CommonHeader, DataResponse } from '../../base';
import { CommonApiParams } from '../../../types';
import { bffFetch } from '../bffFetch';
import { IfHasGiftOrderReq } from './IfHasGiftOrderReq';
import { IfHasOrderResult } from '../types';

/**
 * 查询用户是否领取到该产品
 * @param params api/mall/bff/order/ifHasOrder特有参数
 * @param commonApiParams 公共参数
 * @param commonHeader 统一header参数
 * @returns 产品领取结果
 */
export async function ifHasGiftOrder(
  params: IfHasGiftOrderReq,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
): Promise<AxiosResponse<DataResponse<IfHasOrderResult>>> {
  const result = await bffFetch(commonHeader).post('api/mall/bff/order/ifHasOrder', { ...commonApiParams, ...params });
  return result;
}
