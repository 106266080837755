const HOSTMAP: { [key: string]: string } = {
  'yuanbaobaoxian.cn': 'JJOFFICIAL__',
  'ybaomall.com': 'JJPROMOTE__',
  'ybaomallyy.com': 'JJMARKET__',
  'shouxinbaoxian.com': 'SXOFFICIAL__',
  'sxbpa.com': 'SXPROMOTE__',
  'sxbpayy.com': 'SXMARKET__',
  'yuanbaokc.com': 'KC__',
  'ybaobx.com': 'SK__',
};

export const getHostPrefix = (hostname = '') => {
  if (!hostname) return 'JJOFFICIAL__';
  const hostArr = hostname.split('.');
  const subDomain: string = hostArr?.length >= 2 ? `${hostArr[hostArr.length - 2]}.${hostArr[hostArr.length - 1]}` : 'yuanbaobaoxian.cn';
  return HOSTMAP[subDomain] || 'JJOFFICIAL__';
};
