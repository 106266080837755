import { AxiosResponse } from 'axios';

import { CommonHeader, DataResponse } from '../../base';
import { CommonApiParams } from '../../../types';
import { bffFetch } from '../bffFetch';
import { OrderPersonCheckResult } from '../types';
import { QueryOrderAcceptstatisticReq } from '../queryOrderAcceptstatistic';

/**
 * 获取订单家庭成员领取情况聚合二要素
 * @param params 请求参数
 * @param commonHeader 统一header
 * @returns 订单家庭成员领取结果
 * idoc:
 *  非加密：https://idoc.o2moment.com/idoc/inter/index?projectId=225&interfaceId=7210
 *  加密：https://idoc.o2moment.com/idoc/inter/index?projectId=225&interfaceId=7139
 */
export async function queryOrderAcceptstatisticPersonCheck(
  params: QueryOrderAcceptstatisticReq,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
): Promise<AxiosResponse<DataResponse<OrderPersonCheckResult>, unknown>> {
  const result = await bffFetch(commonHeader).post('api/mall/bff/order/acceptstatistic/personCheck/query', { ...commonApiParams, ...params });
  return result;
}
