/* eslint-disable import/no-dynamic-require */
/*
 * @Author: shixiaoxia
 * @Date: 2022-11-17 15:08:29
 * @LastEditors: cuilinchao
 * @LastEditTime: 2024-12-12 22:05:48
 * @Description: 输入框统一处理
 */
import React, { Component } from 'react';
import { InputItem } from 'antd-mobile-v2';
import classnames from 'classnames';

import { trackEvent } from 'ybcommon/ybutils/statistic';
import { PageInteractionLogManager, InteractionTopic, PageInteractionLogManager2 } from 'ybcommon/ybutils/PageInteractionLogManager';
import { CommonUtil, device } from 'fe-yb-tools';

import styles from './index.less';

const { isIOS, isBaidu, isAndroid } = device;
export default class EachInputItem extends Component {
  static defaultProps = {
    handClassName: '', // 小手类名
    errorShowType: 0, // 异常展示类型：0-原版（仅仅标红）1-异常展示优化版（border包裹，异常信息提示在input下方）2-异常信息由外部传入;
    errorElement: null, // 异常组件 errorShowType===2的时候需要使用
    errorText: '', // 异常信息文本 errorShowType===1的时候需要使用
    disabledCharsStr: '', // 禁止输入的字符的字符串(多个可以放到一个串里面) eg:' _@$'
    // eslint-disable-next-line no-unused-vars
    onChange: (val, callback, inputSpaceFlag = 0) => { }, // 输入框值变化 val 值, callback 值setstate后的回调函数, inputSpaceFlag 输入空格标志 1-本次输入值有空格
    onHandClick: () => { },
    errorPhoneHasChange: false, // 错误状态手机号框是否修改
    errorNameHasChange: false, // 错误状态姓名框是否修改
    errorCertNoHasChange: false, // 错误状态身份证框是否修改
    isPreventFocus: false, // 是否阻止聚焦
    onPreventFocusEmit: () => { }, // 阻止聚焦事件
    disabledCharsStrCallback: () => { }, // 输入了禁用字符之后的回调函数，具体回调逻辑由外面传入
    /**
     * 软件盘右下角的开始按钮/确定按钮是否需要触发blur事件
     */
    startBtnNeedBlur: false,
    onErrorTipClick: () => { }, // 点击错误提示那一行的回调方法
    inputStyle: {}, // 外面传入的Input的Style样式
    inputHandStyle: {}, // 外面传入的小手的Style样式
    showNoErrorFocusingStyle: false, // 命中link3FormAbtest为版本4/6时，当无报错聚焦时，要调整整体样式为：白底加灰色placeholder
  };

  constructor(props) {
    super(props);
    this.selectionEnd = null; // 光标应该在的位置
    this.state = {
      isFocus: false,
    };
  }

  componentDidUpdate() {
    const selectionEnd = document.activeElement?.selectionEnd;
    if (typeof selectionEnd === 'number' && typeof this.selectionEnd === 'number' && this.selectionEnd !== selectionEnd) {
      document.activeElement?.setSelectionRange(this.selectionEnd, this.selectionEnd);
    }
  }

  _textFocus = (val) => {
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(val);
    }
    /** 正在进行聚焦 记录交互 */
    PageInteractionLogManager.startInteraction(InteractionTopic.FocusInput);
    this.interactionId = PageInteractionLogManager2.startInteraction(InteractionTopic.FocusInput);
    this.setState({
      isFocus: true,
    });
    /**
     * 处理输入框没有移动到键盘上方的情况，iOS没有动逻辑，安卓通过计算将页面移动到屏幕中央
     * iOS之前有问题的情况，是页面中使用时foucs事件中又调用了CommonUtil._textScrollToView
     */
    if (isAndroid()) {
      setTimeout(() => {
        if (!window.ybHasResize) {
          this._calcScrollTop(window.innerHeight, window);
          trackEvent('input_not_resize');
        } else {
          CommonUtil._textScrollToView();
        }
      }, 300);
    } else {
      CommonUtil._textScrollToView();
    }
  };

  _calcScrollTop = (visualHeight, scrollObj) => {
    scrollObj = scrollObj || window;
    const { id = '' } = this.props;
    const inputWrap = document.getElementById(id);
    if (!inputWrap) {
      CommonUtil._textScrollToView();
      return;
    }
    const inputTop = inputWrap.getBoundingClientRect().top;
    const pageOffSetY = document.scrollingElement.scrollTop || window.pageOffSetY || 0;
    const offSetY = inputTop > visualHeight / 2 ? pageOffSetY + (inputTop - visualHeight / 2 + inputWrap.offsetHeight + 140) : pageOffSetY - (visualHeight / 2 - inputTop) + inputWrap.offsetHeight + 140;
    // 这里加延时是因为上面的变化会造成页面渲染，需要滚动放到下一次事件队列中
    setTimeout(() => {
      scrollObj.scrollTo(0, offSetY);
    }, 0);
    // 防止出现添加滚动监听后，键盘弹出过程中有滑动事件直接造成blur
    setTimeout(() => {
      window.addEventListener('scroll', this._scrollAction);
    }, 500);
  };

  _scrollAction = () => {
    document.activeElement.blur();
    window.removeEventListener('scroll', this._scrollAction);
  };

  _textBlur = () => {
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur();
    }
    PageInteractionLogManager.endCurInteraction();
    PageInteractionLogManager2.endInteraction(this.interactionId);
    this.setState({
      isFocus: false,
    });
    if (isIOS()) {
      setTimeout(() => {
        const activeElement = document.activeElement;
        if (activeElement.nodeName !== 'INPUT') {
          // 微信浏览器版本6.7.4+IOS12会出现键盘收起后，视图被顶上去了没有下来
          // eslint-disable-next-line no-useless-escape
          const wechatInfo = window.navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i);
          if (!wechatInfo && !isBaidu()) return;
          if (!isBaidu()) {
            const wechatVersion = wechatInfo ? wechatInfo[1] : '';
            const version = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
            if (+wechatVersion.replace(/\./g, '') >= 674 && +version[1] >= 12) {
              const container = document.getElementById('container');
              if (container) {
                container.scrollTop += Math.max(document.body.scrollTop, document.documentElement.scrollTop);
              }
              window.scrollTo(0, Math.max(document.body.scrollTop, document.documentElement.scrollTop));
            }
          }
        }
      }, 100);
    }
  };

  /**
   *  安卓环境根据传参判断是否需要点击右下角关闭键盘
   * @param {keyUp事件} event
   */
  _onKeyUpHandler = (event) => {
    const { startBtnNeedBlur } = this.props;
    if (isAndroid() && startBtnNeedBlur && event?.keyCode === 13) {
      document.activeElement?.blur();
    }
  };

  focus = () => {
    if (this.inputRef) {
      const { isPreventFocus, onPreventFocusEmit } = this.props;
      if (isPreventFocus) {
        // 阻止聚焦，触发阻止聚焦事件
        onPreventFocusEmit();
        return;
      }
      this.inputRef.focus();
    }
  };

  _handleChange = (val) => {
    const { disabledCharsStr, disabledCharsStrCallback, onChange } = this.props;
    // 禁用字符的正则
    const disabledCharsStrRegExp = new RegExp(`[${disabledCharsStr}]+`, 'g');
    if (
      disabledCharsStr
      && val
      && disabledCharsStrRegExp.test(val)
      && document.activeElement
      && (typeof document.activeElement.selectionEnd === 'number')
    ) {
      // 含有禁用字符，去掉禁用字符，同时记录光标应该在的位置
      const valNew = val.replace(disabledCharsStrRegExp, '');
      const selectionEnd = document.activeElement.selectionEnd;
      this.selectionEnd = selectionEnd - (val.length - valNew.length);
      onChange(valNew, 1);
      disabledCharsStrCallback();
    } else { // 可以输入空格 或者没有输入空格
      this.selectionEnd = null; // 解决遗留的先输入正确字符，再输入错误字符，再输入正常字符时出现的光标前移的问题
      onChange(val);
    }
  };

  render() {
    const { onBlur, onFocus, onShowTip, onClickTip, textAlignValue, showHand, highlight, handImg, handStyle, disabledCharsStr, disabledCharsStrCallback, inputContainerClassName, error, errorShowType, errorElement,
      errorText, errorNameHasChange, errorPhoneHasChange, errorCertNoHasChange, className, value, onHandClick, isPreventFocus, onPreventFocusEmit, handClassName, onErrorTipClick, inputStyle, inputHandStyle, showNoErrorFocusingStyle, startBtnNeedBlur, ...otherProps } = this.props;
    const { isFocus } = this.state;
    let inputBoxClass = '';
    if (errorNameHasChange || errorCertNoHasChange || errorPhoneHasChange) {
      inputBoxClass = 'yb-am-inputLightBorderBlackText';
    } else if (error) {
      inputBoxClass = 'yb-am-inputErrBox';
    } else if (showNoErrorFocusingStyle && isFocus) { // 当输入框无报错且聚焦时，调整样式为：白底加灰色placeholder
      inputBoxClass = 'yb-am-noErrorFocusingStyle';
    } else if ((showHand || isFocus || highlight)) {
      inputBoxClass = 'yb-am-guideHighlight';
    } else if (value) { // 输入内容正确时 未选中的状态 用于设置disable的css
      inputBoxClass = 'yb-am-hasValueNormal';
    } else { // 未输入内容时 未选中的状态 用于设置disable的css
      inputBoxClass = 'yb-am-noValueNormal';
    }

    // console.log('log inputBoxClass===', inputBoxClass);

    const InputCom = (
      <InputItem
        ref={(ref) => {
          this.inputRef = ref;
        }}
        {...otherProps}
        value={value}
        className={classnames(className, { [inputContainerClassName]: errorShowType !== 1 })}
        error={error}
        onChange={this._handleChange}
        onFocus={this._textFocus}
        onBlur={this._textBlur}
        onKeyUp={this._onKeyUpHandler}
        autoComplete="off"
        style={inputStyle}
      />
    );
    // 新增：将聚焦的文本域扩大到整个文本框、点击小手也聚焦文本框
    return (
      <div
        style={{ position: 'relative' }}
        id="yb-input-wrap"
        className={styles.inputItem}
        ref={(ref) => {
          // 勿删，外面可能会获取这个wrap元素
          this.inputWrapRef = ref;
        }}
      >
        {showHand && <img alt="" className={classnames(styles.hand, handStyle, handClassName)} style={inputHandStyle} src={handImg || require('./hand.png')} id="input-hand" onClick={onHandClick} />}
        {isPreventFocus ? <div className={styles.coverDiv} onClick={onPreventFocusEmit} /> : null}
        {
          errorShowType === 1 ? (
            <div className={classnames(inputContainerClassName, styles.inputBox, inputBoxClass)}>
              {InputCom}
              <div className={classnames('errorTips', styles.errorTips, error && errorText ? null : styles.none)} onClick={onErrorTipClick}>
                <img className={styles.errorIcon} src={require('./errorIcon.png')} alt="" />
                <span>{errorText}</span>
              </div>
            </div>
          ) : InputCom
        }
        {errorShowType === 2 && errorElement && error ? errorElement : null}
      </div>
    );
  }
}
