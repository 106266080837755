/*
 * @Author: pidejun pidejun@yuanbaobaoxian.com
 * @Date: 2025-01-16 10:56:52
 * @LastEditors: pidejun pidejun@yuanbaobaoxian.com
 * @LastEditTime: 2025-01-17 18:20:15
 * @FilePath: /元保h5/packages/fe-main-web/src/common/utils/bufferOfPhoneNumForSms.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
class BufferOfPhoneNumForSms {
  private static phoneToExpireSet = new Set();

  /**
   * @param phoneNumber 需要缓存的手机号
   * @param bufferTime 缓存时间，单位为毫秒
   */
  static buffer(phoneNumber: string, bufferTime = 0) {
    if (!phoneNumber) return;
    const set = BufferOfPhoneNumForSms.phoneToExpireSet;
    set.add(phoneNumber);
    setTimeout(() => set.delete(phoneNumber), bufferTime);
  }

  /**
   * @param phoneNumber 查询手机号是否未过期
   */
  static isUnexpired(phoneNumber: string) {
    const set = BufferOfPhoneNumForSms.phoneToExpireSet;
    return set.has(phoneNumber);
  }
}

export default BufferOfPhoneNumForSms;
