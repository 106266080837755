/*
 * @Author: cuilinchao
 * @Date: 2024-12-10 17:03:19
 * @LastEditors: cuilinchao
 * @LastEditTime: 2024-12-10 17:40:14
 * @Description: tag已选中图片
 */

import styles from './index.less';

const TagIcon = () => (
  <img src={require('./icon_dui.png')} alt="" className={styles.iconDui} />
);

export default TagIcon;
