import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { CommonHeader } from '../base';
import { getEnvConfigrationByDomain } from '../../envConfig/index';

/**
 * 生成Node Server请求
 * 注意这个方法应该只在Web端被使用
 * @param {commonHeader}
 * @returns
 */
// eslint-disable-next-line max-len
function nodeFetch(commonHeader: CommonHeader): AxiosInstance {
  const baseURL = getEnvConfigrationByDomain().NODE_SERVER_BASEURL;
  // return baseFetch({ baseURL, withCredentials: true }, commonHeader);
  return axios.create({ baseURL, withCredentials: true, headers: commonHeader });
}

/**
 * 从node端获取聚合数据
 * @param apiPath api服务地址
 * @param requestBody 请求体
 * @param commonHeader 公共header
 */
export async function fetchIntegratedDataFromNode<Q, R>(apiPath: string, requestBody: Q, commonHeader: CommonHeader): Promise<AxiosResponse<R, unknown>> {
  return nodeFetch(commonHeader).post(apiPath, requestBody, { headers: commonHeader });
}
