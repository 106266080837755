/*
 * @Author: xiaotao xiaotao@yuanbaobaoxian.com
 * @Date: 2024-11-15 16:22:50
 * @LastEditors: xiaotao xiaotao@yuanbaobaoxian.com
 * @LastEditTime: 2025-01-10 14:40:04
 * @FilePath: /fe-insurance-web/packages/fe-main-web/src/component/NewProtect/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { Fragment } from 'react';
import { YBModal } from 'component';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import classNames from 'classnames';
import { PlanTag } from 'src/sys/mall/components/components/PlanTag';
import styles from './index.less';

const SECTABKEY = 'protect-plan-item';

interface IProps {
  version: number; // 渲染的版本
  productPlanList: Array<any>; // 保障方案数组
  planId: string; // 当前选中的方案Id
  // eslint-disable-next-line
  classnames?: string[];
  onClickPlan: (index: number) => void; // 点击事件回调
}
interface IState {
  dutyList: Array<any>; // 险种责任数组
  visible: boolean; // 是否展示弹框
  selectItem: any; // 选中的险种责任
  selectedIndex: number; // 当前选中的保障方案
  dutyVersion2ByDuty: Array<{ // 版本2数据结构-险种责任维度
    dutyName: string; // 险种责任
    insuredAmountList: Array<{
      insuredAmount: string; // 保证金额
      dutyName: string; // 险种责任
      insuranceTypeDutyDesc: string; // 险种责任说明
    }>;
  }>;
  dutyVersion2ByPlan: Array<{ // 版本2数据结构-保障方案维度
    planName: string; // 方案名称
    insuredAmountList: Array<{
      insuredAmount: string; // 保证金额
      dutyName: string; // 险种责任
      insuranceTypeDutyDesc: string; // 险种责任说明
    }>;
  }>;
}

class NewProtect extends React.Component<IProps, IState> {
  planNames: Array<string>; // 计划名称

  tabsPosition: Array<any>; // 元素坐标

  scrollRef: any; // 滚动元素的ref

  dutyItemRefs = []; // 版本2,保障计划单项

  constructor(props) {
    super(props);
    this.tabsPosition = [];
    const { selectedIndex, planNames, dutyVersion2ByDuty, dutyVersion2ByPlan } = this._getSelectIndexAndPlanNames();
    this.planNames = planNames;
    const dutyList = this._getDutyList(selectedIndex);
    this.state = {
      visible: false,
      selectItem: null,
      selectedIndex,
      dutyList,
      dutyVersion2ByDuty,
      dutyVersion2ByPlan,
    };
  }

  componentDidMount(): void {
    const { version } = this.props;
    if (version > 1 && !!this.planNames?.length) {
      this._getTabsPosition();
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { planId, productPlanList } = this.props;
    const { planId: prePlanId } = prevProps;
    if (planId && prePlanId && prePlanId !== planId) { // 监听planId
      const index = productPlanList.findIndex((item) => item.planId === planId);
      if (index > -1) this._handleIndexChange(index);
    }
  }

  /**
   * @description: 获取当前选中方案的下标
   * @return {*}
   */
  _getSelectIndexAndPlanNames = () => {
    const planNames: Array<string> = [];
    const { productPlanList, planId } = this.props;
    let selectedIndex = 0;
    productPlanList.forEach((element, index) => {
      planNames.push(element.planName);
      if (planId === element.planId) selectedIndex = index;
    });
    const { dutyVersion2ByDuty, dutyVersion2ByPlan } = this._getPlanVersion2(productPlanList, planNames);
    return { selectedIndex, planNames, dutyVersion2ByDuty, dutyVersion2ByPlan };
  };

  // 获取版本2数据
  _getPlanVersion2 = (productPlanList, planNames) => {
    const dutyVersion2ByDuty = [];
    productPlanList.forEach((item, planIndex) => {
      const { productPlanDutyList } = item || {};
      if (!productPlanDutyList?.length) return;
      productPlanDutyList.forEach((element) => {
        const params: Record<string, any> = {};
        const dutyIndex = dutyVersion2ByDuty.findIndex((i) => i.dutyName === element.dutyName);
        if (dutyIndex === -1) { // 之前存在相同险种责任
          Object.assign(params, element);
          params.insuredAmountList = planNames.map(() => ({ insuredAmount: '' }));
          params.insuredAmountList[planIndex].insuredAmount = element.insuredAmount;
          params.insuredAmountList[planIndex].insuranceTypeDutyDesc = element.insuranceTypeDutyDesc;
          params.insuredAmountList[planIndex].dutyName = element.dutyName;
          dutyVersion2ByDuty.push(params);
        } else {
          const result = dutyVersion2ByDuty[dutyIndex];
          result.insuredAmountList[planIndex].insuredAmount = element.insuredAmount;
          result.insuredAmountList[planIndex].insuranceTypeDutyDesc = element.insuranceTypeDutyDesc;
          result.insuredAmountList[planIndex].dutyName = element.dutyName;
        }
      });
    });
    const dutyVersion2ByPlan = planNames.map((name, nameIndex) => {
      const insuredAmountList = [];
      dutyVersion2ByDuty.forEach((item2) => {
        insuredAmountList.push(item2.insuredAmountList[nameIndex]);
      });
      return ({
        planName: name,
        insuredAmountList,
      });
    });
    return { dutyVersion2ByDuty, dutyVersion2ByPlan };
  };

  /**
   * @description: 获取险种责任数据
   * @param {*} planId
   * @return {*}
   */
  _getDutyList = (selectedIndex) => {
    const { productPlanList } = this.props;
    const plan = productPlanList[selectedIndex];
    return (plan && plan.productPlanDutyList?.filter((item) => +item.dutyType !== 2)) || [];
  };

  /**
  * @description:获取每个分类当前的位置
  * @return {*}
  */
  _getTabsPosition() {
    const container = this.scrollRef || document;
    const itemEls = container.querySelectorAll(`.${SECTABKEY}`) || [];
    itemEls.forEach((el) => {
      this.tabsPosition.push(el);
    });
  }

  /**
  * @description: 点击险种计划
  * @param {*} index
  * @return {*}
  */
  _handleIndexChange = (index: number) => {
    const { selectedIndex } = this.state;
    if (selectedIndex === index) return;
    const { onClickPlan } = this.props;
    if (onClickPlan) onClickPlan(index);
    const dutyList = this._getDutyList(index);
    const planName = this.planNames[index];
    trackEvent('insurancedetail_supportplan_plan_click', { plan: planName });
    this._updateActiveTab(index);
    this.setState({
      selectedIndex: index,
      dutyList,
    });
  };

  /**
* @description: 更新处于激活状态的tab
* @return {*}
*/
  _updateActiveTab(index) {
    if (!this.tabsPosition.length) return;
    const itemEl = this.tabsPosition[index];
    if (!itemEl) return;
    const offsetLeft = itemEl.offsetLeft;
    const itemWidth = itemEl.offsetWidth;
    const windowWidth = this.scrollRef.clientWidth;
    let distance = 0;
    if (offsetLeft > windowWidth / 2) {
      distance = offsetLeft + itemWidth / 2 - windowWidth / 2;
    }
    this.scrollRef.scrollTo({
      left: distance,
      behavior: 'smooth',
    });
  }

  /**
   * @description: icon图标点击事件
   * @return {*}
   */
  _handleIconClick = (item) => {
    const { insuranceTypeDutyDesc } = item || {};
    if (!insuranceTypeDutyDesc) return;
    const { selectedIndex } = this.state;
    const planName = this.planNames[selectedIndex];
    trackEvent('insurancedetail_supportplan_plandetailpop_show', { plan: planName, content: item.dutyName });
    this.setState({
      visible: true,
      selectItem: item,
    });
  };

  /**
   * @description: 关闭弹框
   * @return {*}
   */
  _handleCloseModal = (num) => {
    const { selectedIndex, selectItem } = this.state;
    const planName = this.planNames[selectedIndex];
    trackEvent('insurancedetail_supportplan_plandetailpop_click', { plan: planName, content: selectItem.dutyName, btn: num });
    this.setState({
      visible: false,
    });
  };

  _renderModal = () => {
    const { selectItem, visible } = this.state;
    const { insuranceTypeDutyDesc, dutyName } = selectItem || {};
    return (
      visible ? (
        <YBModal
          visible={visible}
          popup
          maskClosable
          transparent={true}
          animationType="slide-up"
          onMaskClick={() => this._handleCloseModal(1)}
        >
          <div className={styles.modalContainer}>
            <div className={styles.modalWrapper}>
              <div className={styles.content}>
                <p className={styles.title}>产品解答</p>
                <div className={styles.topWrapper}>
                  <img src={require('./img/tip.png')} alt="" className={styles.tip} />
                  <span className={styles.subTitle}>{dutyName}</span>
                </div>
                <p className={styles.desc}>{insuranceTypeDutyDesc}</p>
                <p className={styles.btn} onClick={() => this._handleCloseModal(0)}>我知道了</p>
              </div>
            </div>
          </div>

        </YBModal>
      ) : null
    );
  };

  /**
   * @description: 渲染版本1
   * @return {*}
   */
  _renderVersion1 = () => {
    const { classnames } = this.props;
    const { dutyList = [] } = this.state;
    return (
      <div className={classNames(styles.container1, classnames || [])}>
        {
          dutyList?.map((item, index) => {
            const { insuranceTypeDutyDesc } = item || {};
            return (
              <div className={classNames(styles.item, 'item')} key={`${index + 1}`}>
                <div className={classNames(styles.itemLeft, 'itemLeft')} onClick={() => this._handleIconClick(item)}>
                  <span className={styles.value}>{item.dutyName}</span>
                  {!!insuranceTypeDutyDesc?.length && <img src={require('./img/icon.png')} alt="" className={styles.icon} />}
                </div>
                <div className={classNames(styles.itemRight, 'itemRight')}>
                  <span className={styles.value}>{item.insuredAmount}</span>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  /**
   * @description: 渲染版本3
   * @return {*}
   */
  _renderVersion3 = () => {
    const { dutyList } = this.state;
    const { productPlanList, planId } = this.props;
    return (
      <div className={styles.container3}>
        <PlanTag
          selectedPlanId={planId}
          productPlanList={productPlanList}
          onTagChange={(id, idx) => { this._handleIndexChange(idx); }}
        />
        <div className={styles.content}>
          {
            dutyList?.map((item, index) => {
              const { insuranceTypeDutyDesc } = item || {};
              return (
                <div className={styles.item} key={`${index + 1}`}>
                  <div className={styles.itemLeft} onClick={() => this._handleIconClick(item)}>
                    <span className={styles.value}>{item.dutyName}</span>
                    {!!insuranceTypeDutyDesc?.length && <img src={require('./img/icon.png')} alt="" className={styles.icon} />}
                  </div>
                  <div className={styles.itemRight}>
                    <span className={styles.value}>{item.insuredAmount}</span>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  // 渲染保障金额
  _renderAmount = (insuredAmountList, needSetHeight = false) => {
    if (!insuredAmountList?.length) return null;
    return insuredAmountList.map((amountItem, j) => {
      const { insuredAmount } = amountItem;
      const style: Record<string, string> = {};
      if (needSetHeight && this.dutyItemRefs[j]) {
        style.height = this.dutyItemRefs[j].clientHeight;
      }
      return (
        <div
          className={styles.amountItem}
          key={`${j + 1}`}
          style={style}
        >{insuredAmount || '-'}
        </div>
      );
    });
  };

  /**
   * @description: 渲染版本2
   * @return {*}
   */
  _renderVersion2 = () => {
    const { selectedIndex, dutyVersion2ByDuty, dutyVersion2ByPlan } = this.state;
    const selectedByPlanList = dutyVersion2ByPlan[selectedIndex]?.insuredAmountList || [];
    return (
      <div className={styles.container2}>
        <div className={classNames(styles.dutyNameTitle, styles.dutyNameInfo)}>方案详情</div>
        <div className={styles.dutyNameBox}>
          {dutyVersion2ByDuty.map((item, index) => {
            const { dutyName, insuredAmountList } = item || {};
            const selectedByPlanItem = selectedByPlanList?.[index];
            const { insuranceTypeDutyDesc } = selectedByPlanItem || {};
            return (
              <div
                className={styles.dutyItem}
                ref={(_ref) => {
                  if (_ref) this.dutyItemRefs[index] = _ref;
                }}
                key={`${index + 1}`}
              >
                <div
                  className={styles.dutyNameInfo}
                  key={`${index + 1}`}
                  onClick={() => {
                    this._handleIconClick(selectedByPlanItem);
                  }}
                >
                  <div>
                    <span>{dutyName || ''}</span>
                    {!!insuranceTypeDutyDesc?.length && <img src={require('./img/icon.png')} alt="" className={styles.icon} />}
                  </div>
                </div>
                <div
                  className={classNames({
                    [styles.amountBox]: true,
                    [styles.amountBox2]: insuredAmountList.length >= 2,
                  })}
                >
                  {this._renderAmount(insuredAmountList)}
                </div>
              </div>
            );
          })}
        </div>
        <div
          className={classNames({
            [styles.contentBox]: true,
            [styles.contentBox2]: dutyVersion2ByPlan.length >= 2,
          })}
          ref={(e) => this.scrollRef = e}
        >
          {dutyVersion2ByPlan.map((item, index) => {
            const { planName, insuredAmountList } = item;
            const isActive = selectedIndex === index;
            return (
              <div
                className={classNames(`${SECTABKEY}`, styles.contentItem)}
                key={`${index + 1}`}
              >
                <div
                  className={classNames({
                    [styles.tag]: !isActive,
                    [styles.activeTag]: isActive,
                  })}
                  onClick={() => this._handleIndexChange(index)}
                >
                  <span>{planName}</span>
                  {isActive && <img className={styles.arrow} src={require('./img/arrow.png')} alt="" />}
                </div>
                <div
                  className={classNames({
                    [styles.activeAmount]: isActive,
                  })}
                >
                  {this._renderAmount(insuredAmountList, true)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderVersion = () => {
    const { version } = this.props;
    if (version === 3) return this._renderVersion3();
    if (version === 2) return this._renderVersion2();
    return this._renderVersion1();
  };

  render() {
    return (
      <Fragment>
        {this._renderVersion()}
        {this._renderModal()}
      </Fragment>
    );
  }
}

export default NewProtect;
