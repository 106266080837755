import { getHostPrefix } from '../utils/envUtil';

let envConfiguration: Record<string, string> = {};
const hostEnvConfiguration: Map<string, Record<string, string>> = new Map([['JJOFFICIAL__', {}], ['JJPROMOTE__', {}], ['JJMARKET__', {}], ['SXOFFICIAL__', {}], ['SXPROMOTE__', {}], ['SXMARKET__', {}], ['KC__', {}], ['SK__', {}]]);

// 存储当前启用环境变量前缀； 以及各环境对应的环境变量集合
const envMap = {
  currentHostPrefix: 'JJOFFICIAL__', // 默认为经济,
  hostEnvConfiguration,
};

export const getEnvConfiguration = (): Record<string, string> => envConfiguration;

// 函数用于检查字符串是否以Map中某个key开头
function startsWithAnyKey(str: string, mapKeys: IterableIterator<string>): boolean {
  for (const key of mapKeys) {
    if (str.startsWith(key)) {
      return true; // 如果找到以某个key开头的字符串，则返回true
    }
  }
  return false; // 如果没有找到，则返回false
}

/**
 * 初始化envConfig
 * @param hostname
 */
export const initEnvConfiguartionByDomain = (hostname: string): void => {
  const prefix = getHostPrefix(hostname);
  envMap.currentHostPrefix = prefix;
  let envConfig = hostEnvConfiguration.get(prefix);
  if (!envConfig) {
    envConfig = {};
    hostEnvConfiguration.set(prefix, envConfig);
  }

  if (Object.keys(envConfig).length === 0) {
    Object.keys(envConfiguration).forEach((key) => {
      if (envConfig) {
        if (key.startsWith(prefix)) {
          envConfig[key.substring(prefix.length)] = envConfiguration[key];
        } else if (!startsWithAnyKey(key, hostEnvConfiguration.keys())) { // 不以主体key开头的则直接赋值
          envConfig[key] = envConfiguration[key];
        }
      }
    });
  }
};

// 获取host对应的环境变量
export const getEnvConfigrationByDomain = (): Record<string, string> => {
  const prefix = envMap.currentHostPrefix;
  return hostEnvConfiguration.get(prefix) || {};
};

// 初始化全部环境变量
export const initEnvConfiguration = (configuration: Record<string, string>, hostname = ''): void => {
  envConfiguration = configuration;
  if (hostname) {
    initEnvConfiguartionByDomain(hostname);
  }
};
