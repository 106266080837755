import { cloneDeep } from 'lodash-es';
import { action, makeObservable, observable } from 'mobx';

export interface BasicAbtest {
  version: number;
  requestId: number;
  [key: string]: unknown;
}

export interface AbtestModelOptions {
  availableVerions: number[];
  defaultVersion: number;
  /**
   * 用于描述当前实例，一般用于 debug。
   */
  name?: string;
}

type SetOptional<T extends object, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type SimpleAbtestModelOptions = SetOptional<AbtestModelOptions, 'defaultVersion'>;

export abstract class AbtestModel<D extends BasicAbtest = BasicAbtest> {
  /**
   * 不应该被更新
   */
  private options: AbtestModelOptions;

  /**
   * 原始数据，已经被 Object.freeze 处理。
   */
  private raw: D;

  /**
   * 请使用 {@link setVersion} 进行更新。
   *
   * @private
   */
  private version: number;

  /**
   * 不应该被更新
   */
  private requestId: number;

  constructor(abtest: D, options: SimpleAbtestModelOptions) {
    this.options = { ...options, defaultVersion: options.defaultVersion ?? options.availableVerions[0] ?? 1 };
    this.init(abtest);
    makeObservable<AbtestModel, 'version'>(this, {
      version: observable,
      setVersion: action,
    });
  }

  init(abtest: D) {
    this.raw = Object.freeze(cloneDeep(abtest));
    this.setVersion(abtest.version);
    this.requestId = abtest.requestId;
  }

  isAvailableVersion(ver: number) {
    return this.options.availableVerions.includes(ver);
  }

  /**
   * 更新版本
   */
  setVersion(ver: number) {
    // @ts-ignore 暂未定义
    if (__DEV__) {
      if (!this.isAvailableVersion(ver)) {
        // eslint-disable-next-line no-console
        console.warn(`try update abtest '${this.options.name ?? this.constructor.name}' to ${ver}, but it isn't an available version.`);
      }
    }
    this.version = this.isAvailableVersion(ver) ? ver : this.options.defaultVersion;
  }

  setDefaultVersion() {
    this.setVersion(this.getDefaultVersion());
  }

  getVersion() {
    return this.version;
  }

  getDefaultVersion() {
    return this.options.defaultVersion;
  }

  isVersion(ver: number) {
    return this.getVersion() === ver;
  }

  isDefaultVersion(ver?: number) {
    return (ver ?? this.getVersion()) === this.getDefaultVersion();
  }

  restoreVersion() {
    this.setVersion(this.raw.version);
  }

  getRequestId() {
    return this.requestId;
  }

  getRaw() {
    return this.raw;
  }
}
