/**
 * 验证码倒计时
 */
import React, { Component } from 'react';
import { Button } from 'antd-mobile-v2';
import { Toast as ToastUtil } from '@yb/fe-component';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import Fetch, { UCFetch } from 'fetch';
import { getProductEntity, ifCarryUserEntityInLoginApi } from 'src/common/utils/common/entity';
import BufferOfPhoneNumForSms from 'src/common/utils/bufferOfPhoneNumForSms';

export default class SmsCountDown extends Component {
  static defaultProps = {
    onSendFail: () => { }, // 发送验证码失败
    showPhoneTipModal: null, // 5、9、10 展示手机号确认提示弹窗回调
    fetchUrl: '', // 获取验证码请求接口
    againSendText: '', // 重新发送的文案
    // 是否开启手机号定时缓存，若开启则在验证码有效期内不会重复请求验证码
    enablePhoneNumebrBuffer: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      smsDesc: '获取验证码',
      smsBtnEnabled: true,
    };
  }

  componentWillUnmount() {
    if (this._timer) clearInterval(this._timer);
  }

  sendVerifyCode(_tmp) {
    const { enablePhoneNumebrBuffer, onSuccess, onOldFail, onSendFail, isOldVersionType, fetchUrl } = this.props;
    const dom = (
      <div
        style={{
          fontFamily: 'PingFangSC-Regular',
          fontSize: `${pxTOrem(22)}rem`,
        }}
      >
        原验证码仍可用，请继续输入
      </div>
    );
    // 开启号码缓存探知且号码验证码未过期，不请求验证码
    if (enablePhoneNumebrBuffer && BufferOfPhoneNumForSms.isUnexpired(_tmp)) {
      if (isOldVersionType) ToastUtil.info('', 3, null, false, dom);
      else ToastUtil.fail('您的验证码仍在有效期，请输入原验证码登录', 3, null, false);
      return;
    }
    // 产品主体
    const productEntity = getProductEntity();
    const flag = ifCarryUserEntityInLoginApi();
    UCFetch.loadingPost(
      {
        url: fetchUrl || '/api/login/getSmsCode',
        params: { phone: _tmp, groupId: flag ? productEntity : '' },
      },
      (res) => {
        if (onSuccess) onSuccess(res);
        // ToastUtil.info('已发送验证码', 3, null, false);
        this._setLoop();
        // 设置号码缓存
        if (enablePhoneNumebrBuffer) BufferOfPhoneNumForSms.buffer(_tmp, 60000);
      },
      (err) => {
        // console.log('err...', err);
        if (err?.data) {
          onOldFail?.(err?.data, err.code);
        }
        if (err.code === 2013 && isOldVersionType) {
          ToastUtil.info('', 3, null, false, dom);
        } else {
          ToastUtil.fail(err, 3, null, false);
        }
        if (onSendFail) onSendFail(err);
      },
    );
  }

  _doTimerLoop = () => {
    const { againSendText } = this.props;
    if (this._timer) clearInterval(this._timer);
    this._timer = setInterval(() => {
      let { smsDesc } = this.state;
      if (typeof smsDesc === 'number') {
        smsDesc -= 1;
        if (smsDesc === 0) {
          this.setState({
            smsDesc: againSendText || '重新发送',
            smsBtnEnabled: true,
          });
          if (this._timer) clearInterval(this._timer);
          const { showPhoneTipModal } = this.props;
          showPhoneTipModal?.();
        } else {
          this.setState({
            smsDesc,
            smsBtnEnabled: false,
          });
        }
      }
    }, 1000);
  };

  // 获取验证码发送状态
  _getSmsStatus = () => {
    const { smsDesc } = this.state;
    if (typeof smsDesc === 'number') {
      return {
        status: 1,
        leftTime: smsDesc,
      };
    }
    return { status: 0 };
  };

  // 设置验证码发送状态
  _setSmsStatus = (info) => {
    if (info) {
      const { status, leftTime } = info;
      if (status === 1) {
        // 发送中
        this.setState({ smsDesc: +leftTime, smsBtnEnabled: false }, this._doTimerLoop);
      }
    }
  };

  /** 同步组件相关state数据 */
  _syncStateData = (data) => {
    const {
      smsDesc = '获取验证码',
      smsBtnEnabled = true,
    } = data || {};

    this.setState({ smsDesc, smsBtnEnabled }, () => {
      if (!smsBtnEnabled) this._doTimerLoop();
    });
  };

  setSmsEnabled(isEnabled, autoLogin, hasJiyanFail) {
    this.setState({
      smsBtnEnabled: isEnabled,
    }, () => {
      // 自动发送验证码
      if ((hasJiyanFail || autoLogin) && isEnabled) this._handClick();
    });
  }

  _restLoop = () => {
    this.setState({
      smsDesc: '重新发送',
      smsBtnEnabled: true,
    });
    if (this._timer) clearInterval(this._timer);
  };

  _setLoop = () => {
    this.setState(
      {
        smsDesc: __DEV__ ? 5 : 60,
        smsBtnEnabled: false,
      },
      this._doTimerLoop,
    );
  };

  _signSendCode = () => {
    const { onSend, apiURL, params, onSuccess, onOldFail, isOldVersionType } = this.props;
    const { smsDesc } = this.state;
    if (onSend) onSend(smsDesc);
    Fetch.loadingPost(
      {
        url: apiURL,
        params,
      },
      (res) => {
        if (onSuccess) onSuccess(res);
        this._setLoop();
      },
      (err) => {
        if (err?.data) {
          onOldFail?.(err?.data, err.code);
        }
        if (err.code === 2013 && isOldVersionType) {
          const dom = (
            <div
              style={{
                fontFamily: 'PingFangSC-Regular',
                fontSize: `${pxTOrem(22)}rem`,
              }}
            >
              原验证码仍可用，请继续输入
            </div>
          );
          ToastUtil.info('', 3, null, false, dom);
        } else {
          ToastUtil.fail(err, 3, null, false);
        }
      },
    );
  };

  _handClick = () => {
    const { onSend, apiURL, params } = this.props;
    if (apiURL && params) {
      this._signSendCode();
      return;
    }
    if (onSend) {
      const { smsDesc } = this.state;
      const result = onSend(smsDesc);
      console.log(result);
      if (result) this.sendVerifyCode(result);
    }
  };

  // 登录页引导 适老版 按钮
  _renderOldBtn = () => {
    const { smsBtnEnabled, smsDesc } = this.state;
    const { className, disabledClass, text = 's后重新发送' } = this.props;
    let btnclass = className;

    // console.log();

    if (disabledClass) {
      // 按钮禁用时的样式
      btnclass = typeof smsDesc === 'number' ? disabledClass : className;
    }

    return (
      <Button inline disabled={!smsBtnEnabled} className={btnclass} id="btn_getCode" onClick={this._handClick}>
        {typeof smsDesc === 'number' ? `${smsDesc}${text}` : smsDesc}
      </Button>
    );
  };

  /**
   * @method 更新当前的smsDesc和smsBtnEnabled的状态
   * @description 供外面调用，别删
   */
  _handleUpdateState = (data) => {
    const { smsDesc, smsBtnEnabled } = data || {};
    this.setState({ smsDesc, smsBtnEnabled }, this._doTimerLoop);
  };

  render() {
    const { smsBtnEnabled, smsDesc } = this.state;
    const { className, disabledClass, text = 's后重新发送', isOldVersionType } = this.props;
    let btnclass = className;

    if (isOldVersionType) return this._renderOldBtn();

    if (disabledClass) {
      // 按钮禁用时的样式
      btnclass = smsBtnEnabled ? className : disabledClass;
    }
    return (
      <Button inline disabled={!smsBtnEnabled} className={btnclass} id="btn_getCode" onClick={this._handClick}>
        {typeof smsDesc === 'number' ? `${smsDesc}${text}` : smsDesc}
      </Button>
    );
  }
}
