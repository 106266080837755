/*
 * @Author: shixiaoxia
 * @Date: 2024-09-02 19:05:31
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-09-03 11:02:27
 * @Description: 免密登陆
 */
import { AxiosResponse } from 'axios';
import { CommonHeader, DataResponse } from '../../base';
import { CommonApiParams } from '../../../types';
import { bffFetch } from '../bffFetch';
import { OrderAcceptstatisticResult } from '../types';
import { QueryOrderHmaAcceptstatisticReq } from './QueryOrderHmaAcceptstatisticReq';

/**
 * 获取订单家庭成员领取情况
 * @param params 请求参数
 * @param commonHeader 统一header
 * @returns 订单家庭成员领取结果
 *
 */
export async function queryOrderHmaAcceptstatistic(
  params: QueryOrderHmaAcceptstatisticReq,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
): Promise<AxiosResponse<DataResponse<OrderAcceptstatisticResult>, unknown>> {
  const result = await bffFetch(commonHeader).post('api/mall/bff/hma/acceptstatistic/query', { ...commonApiParams, ...params });
  return result;
}
