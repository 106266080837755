/*
 * @Author: cuilinchao
 * @Date: 2024-12-29 22:36:32
 * @LastEditors: cuilinchao
 * @LastEditTime: 2024-12-30 14:59:02
 * @Description: 保障方案
 */
import { useCallback, useEffect, useImperativeHandle, useRef, useState, forwardRef } from 'react';
import classNames from 'classnames';
import { pxTOrem } from 'ybcommon/ybutils/common/common';
import TagIcon from '../TagIcon';

import styles from './index.less';

interface IPlanTag {
  productPlanList: Array<{
    planId: string; // 方案id
    planName: string; // 方案名称
    planFeature: string; // 方案特色
    [t: string]: any; // 其他项
  }>;
  selectedPlanId: string; // 当前选中方案id
  onTagChange: (planId: string, index: number) => void; // tag切换回调
  showPlanFeature?: boolean; // 是否展示方案特色
  tagWidth?: number; // tag宽度
  scrollCount?: number; // 超过几个就滚动
}

// 获取旧值
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const PlanTag = forwardRef((props: IPlanTag, ref) => {
  const {
    productPlanList,
    selectedPlanId,
    onTagChange,
    showPlanFeature = false,
    tagWidth = 90,
    scrollCount = 3,
  } = props;
  const prePlanId = usePrevious(selectedPlanId);
  const [showFeature, setShowFeature] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const tagsRef = useRef<Array<HTMLElement>>([]);

  useEffect(() => {
    const tags = [];
    const container = containerRef.current as HTMLElement;
    const itemEls = container.querySelectorAll(`.${styles.planItem}`) || [];
    itemEls.forEach((el) => {
      tags.push(el);
    });
    tagsRef.current = tags;
  }, []);

  const scroll = useCallback(() => {
    const idx = productPlanList.findIndex((item) => item.planId === selectedPlanId);
    if (idx > -1) {
      const itemEl = tagsRef.current[idx];
      if (!itemEl || !containerRef.current) return;
      const offsetLeft = itemEl.offsetLeft;
      const itemWidth = itemEl.offsetWidth;
      const containerWidth = containerRef.current.clientWidth;
      let distance = 0;
      if (offsetLeft > containerWidth / 2) {
        distance = offsetLeft + itemWidth / 2 - containerWidth / 2;
      }
      containerRef.current.scrollTo({
        left: distance,
        behavior: 'smooth',
      });
    }
  }, [productPlanList, selectedPlanId]);

  useImperativeHandle(ref, () => ({
    scroll,
  }), [scroll]);

  useEffect(() => {
    if (prePlanId !== selectedPlanId) {
      scroll();
    }
  }, [prePlanId, selectedPlanId, scroll]);

  useEffect(() => {
    if (showPlanFeature) {
      // 只有所有的保障方案都配了方案特色才展示
      const flag = productPlanList.find((item) => !item.planFeature);
      setShowFeature(!flag);
    }
  }, [showPlanFeature, productPlanList]);

  return (
    <div
      ref={containerRef}
      className={styles.planBox}
    >
      {productPlanList.map((item, index) => {
        const { planName, planFeature, planId } = item;
        const isActive = selectedPlanId === planId;
        return (
          <div
            className={classNames({
              [styles.planItem]: true,
              [styles.planItemOn]: isActive,
              [styles.itemFlex]: productPlanList.length <= scrollCount,
            })}
            style={{ width: `${pxTOrem(tagWidth)}rem` }}
            onClick={() => {
              if (planId === selectedPlanId) return;
              onTagChange(planId, index);
            }}
            key={item.planId}
          >
            <div className={styles.planName}>{planName}</div>
            {showFeature ? (
              <div className={styles.feature}>{planFeature}</div>
            ) : null}
            {isActive ? <TagIcon /> : null}
          </div>
        );
      })}
    </div>
  );
});
