/*
 * @Author: shixiaoxia
 * @Date: 2024-05-18 10:22:10
 * @LastEditors: chenqiusheng cqs_travis@163.com
 * @LastEditTime: 2025-01-09 16:36:52
 * @Description: 赠险链路服务
 */
import { AxiosResponse } from 'axios';
import { CommonParams } from '../../../../../types';
import { OrderPersonCheckResult } from '../../../../../fetch/bff/types';
import { CommonHeader, DataResponse } from '../../../../../fetch/base';
import { ifHasGiftOrder, queryOrderAcceptstatisticPersonCheck, queryUserInfo } from '../../../../../fetch/bff';
import { mallQueryDetail } from '../../../../../fetch/mallGateway';
import { BaseShuntAbResult, getShuntAbtestAll } from '../../../../../fetch/shunt';
import { LoginGiftDetailParam } from '../index';
import { GiftDetailLoginConfig2Result } from '../types';
import { GiftUnloginConfig2AbtestTemplates } from '../../unlogin/types';
import { ENTITY_AND_PRODUCTID_MAP, Entity } from '../../../../../fetch/mallGateway/types';
import { getGiftProConfirmAbtestVersion } from '../../common/handleAbtest';

export interface GiftLoginConfig2AbtestTemplates {
  giftLinkAbtest: BaseShuntAbResult;
  giftFormAbtest: BaseShuntAbResult;
  giftAudioAbtest: BaseShuntAbResult;
  giftFormHeaderImgAbtest: BaseShuntAbResult;
  giftLoginAndLandingLongImageAbtest: BaseShuntAbResult;
  giftPage1Abtest: BaseShuntAbResult;
  giftPage2Abtest: BaseShuntAbResult;
  giftDetailBackAbtest: BaseShuntAbResult;
  idCardAbtest: BaseShuntAbResult;
  greetingVariantAbtest: BaseShuntAbResult;
  giftProConfirmAbtest: BaseShuntAbResult;
  giftProtocolAbtest: BaseShuntAbResult;
  formPermanentResidentIDCardAbtest: BaseShuntAbResult;
  linkConfidentialTextAbtest: BaseShuntAbResult;
  [key: string]: BaseShuntAbResult | undefined;
}

/** “用户主体”和“产品主体“相匹配。开关关闭时候，这个方法永远返回true */
export function userEntityAndProductEntityIsMatch(entitySwitch: boolean, userEntity: Entity, productEntity: Entity): boolean {
  // 如果开关关闭，认为两个主体是一致的
  if (!entitySwitch) return true;

  return userEntity === productEntity;
}

export const giftDetailLoginConfig2Service = async (
  giftParam: LoginGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailLoginConfig2Result> => {
  const {
    entitySwitch,
    userEntity,
    queryOrderParams,
    queryDetailParams,
    ifHasOrderParams,
    abtestParams,
    commonApiParams,
    commonAbTestParams,
    queryUserInfoParams,
  } = giftParam;
  const { isWifi, productAbb, ybPosition, previewCode, lastVisitTime } = abtestParams || {};
  const abTestCommonParams = {
    productAbb,
    giftProductAbb: productAbb,
  };
  const jiYanLoginAndGiftReloginAndGiftFormParams = {
    ...abTestCommonParams,
    // 重新登录需要参数
    lastVisitTime,
    // 极验需要参数
    isWifi,
  };
  const requests = [];
  // shunt请求，分流
  const loginAbTestParams = [
    { configCode: 'giftLinkAbtest', extraLimit: { ...abTestCommonParams, previewCode: previewCode ? +previewCode : null } },
    { configCode: 'giftFormAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    { configCode: 'giftAudioAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftFormHeaderImgAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftLoginAndLandingLongImageAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage1Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftPage2Abtest', extraLimit: abTestCommonParams },
    { configCode: 'giftDetailBackAbtest', extraLimit: abTestCommonParams },
    { configCode: 'idCardAbtest', extraLimit: abTestCommonParams },
    { configCode: 'greetingVariantAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProConfirmAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftProtocolAbtest', extraLimit: abTestCommonParams },
    { configCode: 'formPermanentResidentIDCardAbtest', extraLimit: abTestCommonParams },
    { configCode: 'linkConfidentialTextAbtest', extraLimit: abTestCommonParams },
    { configCode: 'icpStyleAbtest', extraLimit: abTestCommonParams }, // 页面底部ICP样式ab测
    { configCode: 'guaranteeGenerationStyleAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftUnderFormSwiperAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    { configCode: 'giftHeadFloatSwiperAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftBtnAnimAbtest', extraLimit: abTestCommonParams },
    { configCode: 'giftInsInputUXAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
  ];
  /** 请求的所有AB测编码列表，请求时赋值，用于给分流结果异常的AB测兜底 */
  let abDataArr = loginAbTestParams.map((item) => item.configCode);
  requests.push(getShuntAbtestAll<GiftLoginConfig2AbtestTemplates>(ybPosition, loginAbTestParams, commonAbTestParams, commonApiParams, commonHeader));
  // 请求落地页详情
  requests.push(mallQueryDetail(queryDetailParams, commonApiParams, commonHeader));
  // 登陆态或者有加密userid，获取是否领取+家庭成员信息和领取状态（不进行合并是为了避免中间有请求失败导致接口异常）
  const safeGetOrderData = async () => {
    try {
      const res = await ifHasGiftOrder(ifHasOrderParams, commonApiParams, commonHeader);
      return res;
    } catch (err) {
      return err;
    }
  };
  const safeGetRelationMemeberData = async (): Promise<AxiosResponse<DataResponse<OrderPersonCheckResult>>> => {
    try {
      const res = await queryOrderAcceptstatisticPersonCheck(queryOrderParams, commonApiParams, commonHeader);
      return res;
    } catch (err) {
      return err;
    }
  };

  const queryUserInfoData = async () => {
    try {
      const res = await queryUserInfo(queryUserInfoParams, commonApiParams, commonHeader);
      return res;
    } catch (err) {
      return err;
    }
  };

  requests.push(safeGetOrderData());
  requests.push(safeGetRelationMemeberData());
  requests.push(queryUserInfoData());
  const [giftAbTestDataResponse, productDetailResponse, ifHasOrderResponse, homeMemberResponse, queryUserResponse] = await Promise.all(requests);
  const ybCompanyId: keyof typeof ENTITY_AND_PRODUCTID_MAP = productDetailResponse?.data?.data?.baseInfo?.ybCompanyId;
  const productEntity = ENTITY_AND_PRODUCTID_MAP[ybCompanyId];
  // 转委托是否重新登录
  const prodcutAgentIsNeedLogin = !userEntityAndProductEntityIsMatch(entitySwitch, userEntity, productEntity);
  const isNeedLogin = prodcutAgentIsNeedLogin;
  let unloginGiftAbTestDataResponse;
  // 如果是重新登陆，则需要重新请求非登录态ab测
  if (isNeedLogin) {
    const unloginAbTestParams = [
      { configCode: 'jiYanLoginAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
      // { configCode: 'smsVerificationCodeAbTest', extraLimit: abTestCommonParams },
      { configCode: 'giftLinkAbtest', extraLimit: { ...abTestCommonParams, previewCode: previewCode ? +previewCode : null } },
      { configCode: 'giftFormAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
      { configCode: 'giftAudioAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftFormTipsAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftFormHeaderImgAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftLoginAndLandingLongImageAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftPage1Abtest', extraLimit: abTestCommonParams },
      { configCode: 'giftPage2Abtest', extraLimit: abTestCommonParams },
      { configCode: 'giftLoginBackAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftDetailBackAbtest', extraLimit: abTestCommonParams },
      { configCode: 'idCardAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftAutoOrderAbtest', extraLimit: abTestCommonParams },
      { configCode: 'greetingVariantAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftProConfirmAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftHandAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftProtocolAbtest', extraLimit: abTestCommonParams },
      { configCode: 'formPermanentResidentIDCardAbtest', extraLimit: abTestCommonParams },
      { configCode: 'linkConfidentialTextAbtest', extraLimit: abTestCommonParams },
      { configCode: 'icpStyleAbtest', extraLimit: abTestCommonParams }, // 页面底部ICP样式ab测
      { configCode: 'guaranteeGenerationStyleAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftUnderFormSwiperAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
      { configCode: 'giftHeadFloatSwiperAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftBtnAnimAbtest', extraLimit: abTestCommonParams },
      { configCode: 'giftInsInputUXAbtest', extraLimit: jiYanLoginAndGiftReloginAndGiftFormParams },
    ];
    abDataArr = unloginAbTestParams.map((item) => item.configCode);
    unloginGiftAbTestDataResponse = await getShuntAbtestAll<GiftUnloginConfig2AbtestTemplates>(ybPosition, unloginAbTestParams, commonAbTestParams, commonApiParams, commonHeader);
  }
  const giftAbTestData = unloginGiftAbTestDataResponse ? unloginGiftAbTestDataResponse?.data : giftAbTestDataResponse?.data;
  // ab测默认值赋值
  if (giftAbTestData && giftAbTestData.data) {
    for (const key of abDataArr) {
      const keyData = giftAbTestData.data[key];
      if (!keyData) {
        giftAbTestData.data[key] = { version: 1 };
      } else if (!keyData?.version) {
        keyData.version = 1;
      }
    }
    if (giftAbTestData.data.giftAudioAbtest) {
      giftAbTestData.data.giftAudioAbtest.version = 7; // 默认兜底版本7  产品要求
    }
    if (giftAbTestData.data.giftProConfirmAbtest) {
      giftAbTestData.data.giftProConfirmAbtest.version = getGiftProConfirmAbtestVersion(giftAbTestData.data.giftProConfirmAbtest);
    }
    /** idCardAbtest 下线 3、6 https://yuanbaoshuke.feishu.cn/docx/HXCnd4PWkoCDvOxfzEtcDcemn7c */
    if ([3, 6].includes(giftAbTestData.data?.idCardAbtest?.version)) giftAbTestData.data.idCardAbtest.version = 2;
    if (giftAbTestData.data?.giftFormAbtest && ![2].includes(giftAbTestData.data.giftFormAbtest?.version) && giftAbTestData.data.jiYanLoginAbtest?.version === 14) {
      giftAbTestData.data.jiYanLoginAbtest.version = 9;
    }
    if (giftAbTestData.data.jiYanLoginAbtest?.version === 14) {
      giftAbTestData.data.jiYanLoginAbtest.guidingText = '';
    }
    // 暂时只有 1/2/3/4 4个版本，非这4个版本情况下视为1
    const giftUnderFormSwiperAbtest = giftAbTestData.data.giftUnderFormSwiperAbtest;
    if (giftUnderFormSwiperAbtest && ![1, 2, 3, 4].includes(giftUnderFormSwiperAbtest.version)) {
      giftUnderFormSwiperAbtest.version = 1;
    }
    // 暂时只有 1/2 版本，其它情况视为1
    const giftBtnAnimAbtest = giftAbTestData.data.giftBtnAnimAbtest;
    if (giftBtnAnimAbtest && ![1, 2].includes(giftBtnAnimAbtest.version)) {
      giftBtnAnimAbtest.version = 1;
    }
    // 暂时只有 1/2 版本，其它情况视为1
    const giftHeadFloatSwiperAbtest = giftAbTestData.data.giftHeadFloatSwiperAbtest;
    if (giftHeadFloatSwiperAbtest && ![1, 2].includes(giftHeadFloatSwiperAbtest.version)) {
      giftHeadFloatSwiperAbtest.version = 1;
    }
  }

  // 如果是重新登陆，则只需要返回ab测数据和产品详情数据
  if (isNeedLogin) {
    return {
      abtestData: giftAbTestData,
      productData: productDetailResponse?.data,
    };
  }

  return {
    abtestData: giftAbTestData,
    productData: productDetailResponse?.data,
    orderData: ifHasOrderResponse?.data,
    homeMemberData: homeMemberResponse?.data,
    queryUserInfoData: queryUserResponse?.data,
  };
};
