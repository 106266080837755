/*
 * @Author: jiangwei
 * @Date: 2024-10-20 10:32:02
 * @LastEditors: jiangwei
 * @LastEditTime: 2024-12-16 22:02:34
 * @Descripttion: 同主体各域名同步登录态
 */
import { UCFetch } from 'ybcommon/ybfetch';
import { HOSTMAP, isCN, getSubDomain, getHostPre } from 'ybcommon/ybutils/common/company';
import { uplogCatchError } from 'ybcommon/ybutils/common/common';
import { trackEvent } from 'ybcommon/ybutils/statistic';
import stores from 'store';

let iframeListenr = false;

/**
 * @name: 移除iframe
 * @param {*} event
 * @return {*}
 */
const removeIframe = (event) => {
  const { data: { sync = '', isSuccess } = {} } = event;
  if (!sync) return;
  trackEvent('iframe_synccookie_result', { isSuccess });
  const iframe = document.getElementById(sync);
  if (iframe) {
    document.getElementsByTagName('body')[0].removeChild(iframe);
    // 移除事件监听
    window.removeEventListener('message', removeIframe);
  }
};

/**
 * @name: 监听iframe消息
 * @return {*}
 */
const addIframeListener = () => {
  if (iframeListenr) return;
  iframeListenr = true;
  window.addEventListener('message', removeIframe, false);
};

/**
 * @name: 向body中追加iframe
 * @param {*} encryData 用户信息加密串
 * @param {*} host 同步信息的域名
 * @return {*}
 */
const addIframe = (encryData, host) => {
  addIframeListener();
  const { channel } = stores.userStore;
  const _frame = document.createElement('iframe'); // 创建iframe
  const domain = `${getHostPre()}.${host}`;
  _frame.id = domain;
  _frame.src = `https://${domain}/synccookie.html?encryData=${encryData}&channel=${channel || ''}`;
  _frame.title = 'sync info';
  _frame.width = '0px';
  _frame.height = '0px';
  _frame.frameBorder = 0;
  if (_frame.attachEvent) {
    // 判断是否为ie浏览器
    _frame.attachEvent('onload', () => {
      console.log('====sysc ok');
    });
  } else {
    _frame.onload = function () {
      // 其他浏览器则重新加载onload事件
      console.log('====sysc ok');
    };
  }
  _frame.onerror = function (err) {
    console.log('====sysc err', err);
  };
  document.getElementsByTagName('body')[0].appendChild(_frame); // 将iframe标签嵌入到body中
};

/**
 * @name: 同步用户登录态
 * @param {*} host 当前域名
 * @return {*}
 */
export const syncUserCookie = (host) => {
  const company = isCN(host) ? 'yuanbao' : 'shouxin';
  const subDomain = getSubDomain(host);
  // 如果当前域名不需要同步登录态，直接返回
  if (!HOSTMAP[subDomain]?.needSyncCookie) return;
  // 从所有域名枚举中查找需要同步的域名
  const syncHostArr = [];
  for (const key in HOSTMAP) {
    if (key !== subDomain && HOSTMAP[key]?.company?.indexOf(company) > -1 && HOSTMAP[key]?.needSyncCookie) {
      // 同一主体&&需要同步登录态
      syncHostArr.push(key);
    }
  }
  // 遍历需要同步的域名，依次获取用户信息加密串
  syncHostArr.forEach((_host) => {
    UCFetch.post('/api/cross/encrypt')
      .then((res) => {
        // 使用iframe同步用户信息
        addIframe(res, _host);
      })
      .catch((err) => {
        uplogCatchError(err);
      });
  });
};
