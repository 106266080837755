/*
 * @Author: surun
 * @Date: 2022-11-02 20:35:28
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2025-01-14 20:20:57
 * @Description: 跳转相关
 */
import { TianjiFetch } from 'fetch';
import { Toast } from '@yb/fe-component';
import { openHrefUrl } from 'store/UrlTools';
import { isWeMini, isTTMini } from 'utils/device';
import { isShouxin, isCN } from 'ybcommon/ybutils/common/company';

// 获取打开小程序的scheme 并拉起小程序
export const jumpToMini = (path, query, userIdencrypt = '') => {
  TianjiFetch.post('api/mall/miniapp/api/miniapp/generateScheme', {
    path,
    query,
    userIdencrypt,
  })
    .then((data) => {
      if (data) window.location.href = data;
    })
    .catch((err) => {
      Toast.fail(err);
    });
};

// 获取打开小程序的UrlLink 拉起小程序
export const jumpToMiniByUrlLink = (path, query) => {
  TianjiFetch.post('api/mall/miniapp/api/miniapp/generateURLLink', { path, query })
    .then((data) => {
      if (data) window.location.href = data;
    })
    .catch((err) => {
      Toast.fail(err);
    });
};

/**
   * 跳转到小程序
   * @param {*} jumpUrl 跳转地址
   * @return {void}
   */
export const jumpToMiniProgram = (jumpUrl, isReplace = false) => {
  console.log('[jumpUtil][jumpToMiniProgram]jumpUrl: ', jumpUrl);
  if (isWeMini()) {
    let path = jumpUrl;
    if (jumpUrl?.includes('?')) path = jumpUrl.split('?')?.[0];
    if (isShouxin()) {
      const tabbarPages = [
        '/sxmp/pages/home/index',
        '/sxmp/pages/product/index',
        '/sxmp/pages/my/index',
      ];
      if (tabbarPages.find((x) => x.includes(path))) {
        window.wx.miniProgram.switchTab({ url: jumpUrl });
        return;
      }
    }
    if (isReplace) {
      window.wx.miniProgram.redirectTo({
        url: jumpUrl,
      });
    } else {
      window.wx.miniProgram.navigateTo({
        url: jumpUrl,
      });
    }
  } else {
    if (!jumpUrl) jumpUrl = '';
    const [path, query = ''] = jumpUrl.split('?');
    jumpToMini(path, query);
  }
};

/**
   * 跳转到抖音小程序小程序
   * @param {*} jumpUrl 跳转地址
   * @return {void}
   */
export const jumpToTTMiniProgram = (jumpUrl, isReplace = false) => {
  console.log('[jumpUtil]-[jumpToTTProgram]: ', jumpUrl);
  if (isTTMini()) {
    let path = jumpUrl;
    if (jumpUrl?.includes('?')) path = jumpUrl.split('?')?.[0];
    const tabbarPages = isShouxin() ? [
      '/ttsx/pages/home/index',
      '/ttsx/pages/my/index',
    ] : [
      '/ttyb/pages/home/index',
      '/ttyb/pages/my/index',
    ];
    if (tabbarPages.find((x) => x.includes(path))) {
      window.tt.miniProgram.switchTab({ url: jumpUrl });
      return;
    }
    if (isReplace) {
      window.tt.miniProgram.redirectTo({
        url: jumpUrl,
      });
    } else {
      window.tt.miniProgram.navigateTo({
        url: jumpUrl,
      });
    }
  }
};

/**
   * 使用小程序打开一个H5页面
   * @param {string} h5url
   */
export const openH5InMini = (h5url) => {
  const miniWebViewPath = '/ybsc/pages/web/index';
  const miniWebViewQuery = `webPath=${encodeURIComponent(h5url)}&type=3`;
  jumpToMini(miniWebViewPath, miniWebViewQuery);
};

/**
   * 根据跳转类型跳转
   * @param {*} type 0-h5页面 1-小程序页面  2-第三方页面 3-小程序内h5 -1 不跳转
   * @param {*} jumpUrl 跳转地址
   */
export const jumpByEnvType = (type, jumpUrl) => {
  if (type === 0) {
    openHrefUrl(jumpUrl);
  } else if (type === 1) {
    jumpToMiniProgram(jumpUrl);
  } else if (type === 2) {
    window.location.href = jumpUrl;
  } else if (type === 3) {
    const jumpType = jumpUrl.indexOf('https://') === 0 ? 3 : 4;// 3: 完整链接 4:相对路径
    let appName = '';
    if (isCN() || __DEV__) {
      appName = 'ybsc';
    } else if (isShouxin()) {
      appName = 'sxmp';
    }
    const newUrl = `/${appName}/pages/web/index?type=${jumpType}&webPath=${encodeURIComponent(jumpUrl)}`;
    jumpToMiniProgram(newUrl);
  }
};

/**
 * @description: 新跳转方式
 * @param {*} type 2:跳转h5 3:跳转微信小程序 4:跳转抖音小程序(目前不会有h5直接唤起抖音小程序的场景,只会存在抖音小程序内加载h5, 点击h5的配置项,在打开抖音小程序)
 * @param {*} jumpUrl
 * @return {*}
 */
export const newJumpByEnvType = (type, jumpUrl) => {
  if (type === 2) {
    // 小程序环境内的h5打开h5
    if (isWeMini() || isTTMini()) {
      const jumpType = jumpUrl.indexOf('https://') === 0 ? 3 : 4;// 3: 完整链接 4:相对路径
      let appName = '';
      if (isCN() || __DEV__) {
        appName = isWeMini() ? 'ybsc' : 'ttyb';
      } else if (isShouxin()) {
        appName = isWeMini() ? 'sxmp' : 'ttsx';
      }
      const newUrl = `/${appName}/pages/web/index?type=${jumpType}&webPath=${encodeURIComponent(jumpUrl)}`;
      if (isWeMini()) {
        jumpToMiniProgram(newUrl);
      } else {
        jumpToTTMiniProgram(newUrl);
      }
    } else {
      // h5原生 跳转 h5
      openHrefUrl(jumpUrl);
    }
  } else if (type === 3) {
    // 微信小程序内可能存在 h5->唤起小程序, 原生跳转原生页
    jumpToMiniProgram(jumpUrl);
  } else if (type === 4) {
    // 抖音小程序内, 只存在小程序环境内h5跳转抖音小程序原生页面
    jumpToTTMiniProgram(jumpUrl);
  }
};
