import { isNil } from 'lodash-es';
import { InteractionTopic } from './type';

type Callback = (interaction: InteractionTopic, id: number) => boolean;

class Manager {
  /** 交互结束后回调数组 */
  private onInteractionEnds: Array<Callback> = [];

  /** 交互开始回调数组 */
  private onInteractionStarts: Array<Callback> = [];

  /** 当前正在执行的交互 */
  private interactions: { topic: InteractionTopic; id: number }[] = [];

  /** 用于表示 interaction 的唯一 id，递增且保证 > 0 */
  private id = 1;

  /**
   * 添加交互结束监听回调
   * @param cb 待添加的回调函数
   */
  addInteractionEndCB(cb: Callback) {
    this.onInteractionEnds.push(cb);
  }

  /**
   * 删除 end callback 列表中的回调函数
   * @param cb 需要删除的回调函数
   */
  removeInteractionEndCB(cb: Callback) {
    const pos = this.onInteractionEnds.indexOf(cb);
    if (pos >= 0) {
      this.onInteractionEnds.splice(pos, 1);
    }
  }

  /**
   * 添加交互开始监听回调
   * @param cb 待添加的回调函数
   */
  addInteractionStartCB(interactionStartCB: Callback) {
    this.onInteractionStarts.push(interactionStartCB);
  }

  /**
   * 删除 start callback 列表中的回调函数
   * @param cb 需要删除的回调函数
   */
  removeInteractionStartCB(cb: Callback) {
    const pos = this.onInteractionStarts.indexOf(cb);
    if (pos >= 0) {
      this.onInteractionStarts.splice(pos, 1);
    }
  }

  /**
   * 开始交互回调
   * @param topic 交互类型
   * @returns 本次交互对应的唯一 id
   */
  startInteraction = (topic: InteractionTopic) => {
    const id = this.id;
    this.interactions.push(Object.freeze({ topic, id }));

    if (this.onInteractionStarts.length) {
      const newInteractionStarts: Callback[] = [];
      this.onInteractionStarts.forEach((onInteractionStart) => {
        const isContinue = onInteractionStart(topic, id);
        if (isContinue) {
          newInteractionStarts.push(onInteractionStart);
        }
      });
      this.onInteractionStarts = newInteractionStarts;
    }

    this.id += 1;
    return id;
  };

  /**
   * 结束交互回调
   * @param id 交互唯一 id，当传入空时无作用。
   */
  endInteraction = (id: number | undefined | null) => {
    if (isNil(id)) {
      return;
    }

    const pos = this.interactions.findIndex((item) => item.id === id);
    if (pos < 0) {
      return;
    }

    const { topic } = this.interactions[pos];

    if (this.onInteractionEnds.length) {
      const newInteractionEnds: Callback[] = [];
      this.onInteractionEnds.forEach((onInteractionEnd) => {
        const isContinue = onInteractionEnd(topic, id);
        if (isContinue) {
          newInteractionEnds.push(onInteractionEnd);
        }
      });
      this.onInteractionEnds = newInteractionEnds;
    }

    this.interactions.splice(pos, 1);
  };

  getInteractions() {
    return this.interactions.slice(0);
  }
}

/**
 * 模仿 PageInteractionLogManager 临时使用的实例，目前只用于处理 ShowModal Topic。
 *
 * 新增一个 Manager 是因为:
 *
 * 1. PageInteractionLogManager 的 ShowModal Topic 在 YBModal 中的判断不准确。
 *
 * 2. 可能出现多层弹窗的情况，PageInteractionLogManager 只支持一层弹窗
 *
 * 使用上应该以 PageInteractionLogManager 为主，之后尽量修复 PageInteractionLogManager 中 ShowModal Topic 的问题，并删除 PageInteractionLogManager2。
 */
export const PageInteractionLogManager2 = new Manager();
