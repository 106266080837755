import { AxiosResponse } from 'axios';

import { CommonHeader, DataResponse } from '../../base';
import { CommonApiParams } from '../../../types';
import { mallGatewayFetch } from '../mallGatewayFetch';
import { MallQueryDetailReq } from './MallQueryDetailReq';
import { MallQueryDetailResult } from '../types';

/**
 * 获取产品详情
 * @param params /api/mall/cq/insurance/shortProduct/queryDetail 特有参数
 * @param commonApiParams 公共参数
 * @param commonHeader 通用header
 * @returns 产品详情
 */
export async function mallQueryDetail(
  params: MallQueryDetailReq,
  commonApiParams: CommonApiParams,
  commonHeader: CommonHeader,
): Promise<AxiosResponse<DataResponse<MallQueryDetailResult>>> {
  // 短险、赠险 1.0  idoc: https://idoc.o2moment.com/idoc/inter/index.html?projectId=225&interfaceId=7457
  // if (insuranceType === InsuranceType.SHORT_AND_GIFT_VERSION1) {
  //   const result = await mallFetch(commonHeader)
  //  .post('/api/mall/cq/insurance/queryDetail', { insuranceId });
  //   return result;
  // }

  const { ...queryData } = params;
  const data = { ...commonApiParams, ...queryData };
  // if (insuranceType === InsuranceType.LONG_INSURANCE) {
  //   const result = await mallFetch(commonHeader)
  //  .post('/api/h5/insurance/v2/queryDetail', { insuranceId });
  //   return result;
  // }

  // if (insuranceType === InsuranceType.ZERO_PLUS_LONGL) {
  //   const result = await mallGatewayFetch(commonHeader).post('/api/mall/cq/insurance/combineProduct/queryDetail', data);
  //   return result;
  // }

  // if (insuranceType === InsuranceType.ONE_PLUSE_TWELEVE) {
  //   const result = await mallGatewayFetch(commonHeader).post('/api/mall/cq/insurance/coupleProduct/queryDetail', data);
  //   return result;
  // }

  // 默认走2.0
  const result = await mallGatewayFetch(commonHeader).post('/api/mall/cq/insurance/shortProduct/queryDetail', data);
  return result;
}
