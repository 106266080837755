/*
 * @Author: 朱晓真
 * @Date: 2023-04-18 20:14:33
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2025-01-14 20:21:01
 * @Description: 背景音乐
 */
import { PureComponent } from 'react';
import { v4 as uuidV4 } from 'uuid';
import AudioBGManager from 'src/common/audioBGManager';
import { trackEvent } from 'ybcommon/ybutils/statistic';

interface IState {
  audioSrc: string;
  /** 是否循环播放 */
  isLoop: boolean;
}

interface IProps {
  onPlay?: (e: React.SyntheticEvent<HTMLAudioElement, Event>) => void;
  onPlayEnd?: () => void;
  onStatic?: (eventName: string, data) => void;
}

/**
 * 播放状态
 */
enum IPlayStatusEnum {
  /** 播放中 */
  playing = 1,
  /** 播放结束 */
  playEnd = 2,
  /** 无状态 */
  none = -1,
  /** 播放暂停 */
  paused = 3,
  /** 播放失败 */
  failed = 4,
  /** 信息加载 */
  pending = 5,
}

/**
 * 是否阻止自收集的埋点上报
 */
const isAudioEventPrevent = false;

export default class BGAudioCom extends PureComponent<IProps, IState> {
  /**
   * eslint 被必填属性，需要有默认值，当前默认值为undefined
   */
  static defaultProps = {
    onPlay: undefined,
    onPlayEnd: undefined,
    onStatic: undefined,
  };

  playInstance: AudioBGManager;

  playStatus = IPlayStatusEnum.none;

  audioTraceId = uuidV4();

  constructor(props) {
    super(props);
    this.state = {
      audioSrc: '',
      isLoop: false,
    };
  }

  componentDidMount(): void {
    const params = {} as any;
    if (this.playInstance) {
      params.initCanPlay = this.playInstance.getIsHandPage && !this.playInstance.getIsBlock;
      if (!params.initCanPlay) {
        params.canNotPlayReason = !this.playInstance.getIsHandPage ? 2 : 1;
      }
    }
    this.onStatic('h5_fe_audio_mount', params);
  }

  onStatic = (eventName: string, params?) => {
    if (!eventName) return;
    if (isAudioEventPrevent && eventName.indexOf('h5_fe_audio') !== -1) {
      /**
       * 阻止自收集买点上报
       * 且埋点属于自收集埋点
       */
      return;
    }
    const { onStatic } = this.props;
    const { audioSrc } = this.state;
    params = {
      audioSrc,
      ...(this.trackParams || {}),
      ...params,
      visibilityState: document.visibilityState,
      playStatus: this.playStatus,
      audioTraceId: this.audioTraceId,
    };
    if (this.audioRef) {
      params.elementPaused = this.audioRef.paused;
      params.elementEnded = this.audioRef.ended;
      const err = this.audioRef.error;
      if (err && err.message) {
        params.elementError = err.message;
      }
    }
    if (this.playInstance) {
      params.isHandPage = this.playInstance.getIsHandPage;
    }
    if (typeof onStatic === 'function') {
      onStatic(eventName, params);
    } else {
      trackEvent(eventName, params);
    }
  };

  playInRefAudio = () => {
    this.audioRef?.play()
      ?.catch((err) => {
        console.info('h5_fe_audio_playFailed', err);
        const params = {
          err,
          errMsg: '',
        };
        if (err && err.toString) {
          params.errMsg = err.toString();
        }
        this.playStatus = IPlayStatusEnum.failed;
        this.onStatic('h5_fe_audio_playFailed', params);
      });
  };

  audioRef: HTMLAudioElement;

  /**
   * 埋点上报的额外参数
   */
  trackParams: any = {};

  /**
   * 播放的埋点上报
   */
  _playEvent = (targetEle: HTMLAudioElement) => {
    const { audioSrc } = this.state;
    const params = { position: targetEle?.src || audioSrc, ...this.trackParams };

    this.onStatic('h5_yb_shenyinyindao_play', params);
  };

  /**
   * 播放方法
   * @param src 音频链接
   * @param isReplay 是否为重新播放
   * @param trackParams 播放的埋点参数
   * @param otherParams 其他参数
   */
  play = (src?: string, isReplay = false, trackParams = {}, playActionData = {}) => {
    const { audioSrc } = this.state;
    this.trackParams = trackParams || {};
    const eventParams = {
      isResume: 2,
      audioSrc,
      nextAudioSrc: src,
      ...playActionData,
    } as any;
    if (src !== audioSrc) {
      if (this.playStatus === IPlayStatusEnum.playing || this.playStatus === IPlayStatusEnum.pending) {
        this.onStatic('h5_fe_audio_do_play_pausedAudio', { ...eventParams });
        eventParams.isResume = 1;
      }
      this.pause();
      this.audioTraceId = uuidV4();
      this.playStatus = IPlayStatusEnum.pending;
      this.trackParams = trackParams || {};
      this.setState({
        audioSrc: src,
        isLoop: false,
      }, () => {
        this.playInRefAudio();
      });
    } else if (isReplay) {
      this.replay();
    } else {
      this.playInRefAudio();
    }
    eventParams.audioSrc = eventParams.audioSrc || src;
    this.onStatic('h5_fe_audio_do_play', eventParams);
  };

  /**
   * 循环播放
   * @param src 音频链接
   */
  loopPlay = (src: string) => {
    const { audioSrc } = this.state;
    if (src && src !== audioSrc) return;
    this.pause();
    this.audioTraceId = uuidV4();
    this.playStatus = IPlayStatusEnum.pending;
    this.trackParams = {};
    this.setState({
      audioSrc: src,
      isLoop: true,
    }, () => {
      this.playInRefAudio();
    });
  };

  /**
   * 重新播放
   */
  replay = () => {
    this.setCurrentTime();
    setTimeout(() => {
      this.playInRefAudio();
    }, 100);
  };

  /**
   * 暂停播放
   */
  pause = () => {
    if (!this.audioRef) return;
    this.audioRef.pause();
    this.playStatus = IPlayStatusEnum.paused;
    this.trackParams = {};
  };

  /**
   * 暂停并清除音频
   * @param src 暂停清除的音频链接
   */
  stopAudio = (src: string) => {
    const { audioSrc } = this.state;
    if (src === audioSrc) {
      this.onStatic('h5_fe_audio_do_stopAudio', {
        clearAudioSrc: src,
      });
      this.audioRef.pause();
      this.playStatus = IPlayStatusEnum.none;
      this.trackParams = {};
      this.setState(({ audioSrc: '' }));
      return true;
    }
    return false;
  };

  /**
   * 设置播放进度，父级调用，勿删
   * @param seconds 播放进度，默认0，从头开始
   */
  setCurrentTime = (seconds = 0) => {
    if (this.audioRef) {
      this.audioRef.currentTime = seconds;
    }
  };

  onPlay = (e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    const { onPlay } = this.props;
    const targetEle = e?.target as HTMLAudioElement;
    this._playEvent(targetEle);
    onPlay?.(e);
  };

  render() {
    const { onPlayEnd } = this.props;
    const { audioSrc, isLoop } = this.state;
    return (
      audioSrc
        ? (
          <audio
            ref={(ref) => { if (ref) this.audioRef = ref; }}
            loop={isLoop}
            onPlay={(e) => {
              this.onPlay(e);
              // console.info('h5_fe_audio_played', e);
              this.onStatic('h5_fe_audio_played');
              this.playStatus = IPlayStatusEnum.playing;
            }}
            onPause={(/* e */) => {
              // console.info('h5_fe_audio_pauseed', e);
              this.onStatic('h5_fe_audio_pauseed');
              this.playStatus = IPlayStatusEnum.paused;
            }}
            onEnded={(/* e */) => {
              onPlayEnd?.();
              // console.info('h5_fe_audio_playEnd', e);
              this.onStatic('h5_fe_audio_playEnd');
              this.playStatus = IPlayStatusEnum.playEnd;
            }}
            src={audioSrc}
            // onLoadStart={(/* e */) => {
            //   // 开始加载
            //   // console.info('h5_fe_audio_element_event', 'loadStart', e);
            //   // this.onStatic('h5_fe_audio_element_event', { eventName: 'loadStart' });
            // }}
            // onLoadedMetadata={(/* e */) => {
            //   // 源数据加载完毕
            //   // console.info('h5_fe_audio_element_event', 'loadedMetaData', e);
            //   // this.onStatic('h5_fe_audio_element_event', { eventName: 'loadedMetaData' });
            // }}
            // onLoadedData={(/* e */) => {
            //   // 数据开始加载
            //   // console.info('h5_fe_audio_element_event', 'loadedData', e);
            //   // this.onStatic('h5_fe_audio_element_event', { eventName: 'loadedData' });
            // }}
            // onProgress={(/* e */) => {
            //   // 加载中
            //   // console.info('h5_fe_audio_element_event', 'progress', e);
            //   // this.onStatic('h5_fe_audio_element_event', { eventName: 'progress' });
            // }}
            // onCanPlay={(/* e */) => {
            //   // 播放状态变化
            //   // console.info('h5_fe_audio_element_event', 'canPlay', e);
            //   // this.onStatic('h5_fe_audio_element_event', { eventName: 'canPlay' });
            // }}
            // onCanPlayThrough={(/* e */) => {
            //   // 可以播放整个音频，音频播放夹杂已完全完成了
            //   // console.info('h5_fe_audio_element_event', 'canPlayThrough', e);
            //   // this.onStatic('h5_fe_audio_element_event', { eventName: 'canPlayThrough' });
            // }}
            // onWaiting={(/* e */) => {
            //   // 等待中，由于没有数据而导致的暂停时播放
            //   // console.info('h5_fe_audio_element_event', 'waiting', e);
            //   // this.onStatic('h5_fe_audio_element_event', { eventName: 'waiting' });
            // }}
            // onPlaying={(/* e */) => {
            //   // waiting结束后的播放触发
            //   // console.info('h5_fe_audio_element_event', 'playing', e);
            //   // this.onStatic('h5_fe_audio_element_event', { eventName: 'playing' });
            // }}
            // onVolumeChange={(/* e */) => {
            //   // 音量变化
            //   // console.info('h5_fe_audio_element_event', 'volumeChange', e);
            //   // this.onStatic('h5_fe_audio_element_event', { eventName: 'volumeChange' });
            // }}
            preload="auto"
            autoPlay
          />
        )
        : null
    );
  }
}
