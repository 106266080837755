/*
 * @Author: shixiaoxia
 * @Date: 2024-05-21 15:36:21
 * @LastEditors: 吉小雨 jixiaoyu@yuanbaobaoxian.com
 * @LastEditTime: 2024-11-04 18:06:32
 * @Description: gift/middle数据获取
 */
import { CommonParams } from '../../../../types';
import { getShuntAbtestAll } from '../../../../fetch/shunt';
import { CommonHeader } from '../../../../fetch/base';
import { IfHasGiftOrderReq, QueryOrderAcceptstatisticReq, QueryUserInfoReq } from '../../../../fetch/bff';
import { MallQueryDetailReq } from '../../../../fetch/mallGateway';
import { giftDetailLoginConfig1Service } from './config1';
import { giftDetailLoginConfig2Service } from './config2';
import { GiftNewConfigtestTemplates } from '../types';
import { GiftDetailLoginConfig2Result, GiftDetailLoginResult } from './types';
import { isRequestError } from '../../../../utils/httperr';
import { Entity } from '../../../../fetch/mallGateway/types';

interface AbtestParams {
  productAbb: string; // 产品简称
  isWifi: number; // 1 是: 2 否
  previewCode?: string; // 预览code
  ybPosition?: string; // position
  landingPageVersion?: number; // 落地页版本
  lastVisitTime: string; // 上次访问时间
}

// 赠险请求参数
export interface LoginGiftDetailParam {
  entitySwitch: boolean; // 是否开关开启
  userEntity: Entity; // 用户主体
  queryOrderParams: QueryOrderAcceptstatisticReq; // 请求家庭信息
  queryDetailParams: MallQueryDetailReq; // 请求产品详情
  ifHasOrderParams: IfHasGiftOrderReq; // 请求是否有订单
  abtestParams: AbtestParams;
  queryUserInfoParams?: QueryUserInfoReq;
}

export async function giftDetailLoginService(
  giftParam: LoginGiftDetailParam & CommonParams,
  commonHeader: CommonHeader,
): Promise<GiftDetailLoginResult> {
  const {
    abtestParams,
    commonApiParams,
    commonAbTestParams,
  } = giftParam;

  const { productAbb, ybPosition } = abtestParams;

  // 赠险新配置版ab测请求
  const giftNewConfigAbtestParams = [
    { configCode: 'giftNewConfigAbtest', extraLimit: { productAbb, giftProductAbb: productAbb } },
  ];
  const giftNewConfigAbtestDataResponse = await getShuntAbtestAll<GiftNewConfigtestTemplates>(ybPosition, giftNewConfigAbtestParams, commonAbTestParams, commonApiParams, commonHeader);
  // 如果 giftNewConfigAbtest 返回结果报错，需要直接返回,不需要继续请求
  if (isRequestError(giftNewConfigAbtestDataResponse.data)) {
    return {
      giftNewConfigAbtestData: giftNewConfigAbtestDataResponse?.data,
    };
  }

  const { giftNewConfigAbtest } = giftNewConfigAbtestDataResponse?.data?.data || {};
  const { version: giftNewConfigAbtestVersion } = giftNewConfigAbtest || {};

  // 如果是走新配置
  if (giftNewConfigAbtestVersion === 2) {
    // 获取新配置赠险链路数据
    const giftDataResult: GiftDetailLoginConfig2Result = await giftDetailLoginConfig2Service(giftParam, commonHeader);
    return {
      giftNewConfigAbtestData: giftNewConfigAbtestDataResponse?.data,
      ...giftDataResult,
    };
  }
  // 获取老配置赠险链路数据
  const giftDataResult = await giftDetailLoginConfig1Service(giftParam, commonHeader);
  const result = {
    giftNewConfigAbtestData: giftNewConfigAbtestDataResponse?.data,
    ...(giftDataResult),
  };
  return result;
}
