/*
 * @Author: shixiaoxia
 * @Date: 2024-08-29 17:50:44
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-10-17 20:56:18
 * @Description: 请填写简介
 */
import { AxiosInstance } from 'axios';
import { getEnvConfigrationByDomain } from '../../envConfig/index';
import { CommonAbtestParams } from '../../types';
import { baseFetch, CommonHeader } from '../base';
import { AbTestCodeParam, AbtestParamListItem, ShuntAllAbTestParam } from './types';

export function composeAbtestParams(
  ybPosition: string | undefined,
  abTestParamsList: AbTestCodeParam[],
  commonAbtestData: CommonAbtestParams,
): ShuntAllAbTestParam {
  const shuntAppId = getEnvConfigrationByDomain().SHUNT_APP_ID;
  const paramList: AbtestParamListItem[] = abTestParamsList.map((item: AbTestCodeParam): AbtestParamListItem => {
    const { configCode, extraLimit } = item;
    return {
      configCode,
      params: {
        ...commonAbtestData,
        ...extraLimit,
      },
    };
  });
  return {
    shuntAppId,
    ybPosition,
    paramList,
  };
}

/**
 * 拼接分流降级到cdn时，json文件需要的参数
 * 会过滤掉每个分流实验中的 'userId', 'uuid', 'channel', 'ip', 'orderId', 'userIdencrypt' 这几个参数
 * @param abTestConfigParams
 * @returns string
 */
export function makeCdnParams(abTestConfigParams: AbtestParamListItem[]): string {
  const filterKeys = ['userId', 'uuid', 'channel', 'ip', 'orderId', 'userIdencrypt'];
  const urlParams: string[] = [];
  abTestConfigParams.forEach((item) => {
    urlParams.push(`configKey=${item.configCode}`);
    const testConfigParams = item.params;
    Object.keys(testConfigParams).sort().forEach((key) => {
      if (item && testConfigParams[key] !== null && testConfigParams[key] !== undefined) {
        if (filterKeys.indexOf(key) === -1) {
          const value = testConfigParams[key];
          urlParams.push(`${key}=${value}`);
        }
      }
    });
  });
  return urlParams.join('&');
}

function handleShuntAllAbtestData(resultData: Record<string, any>): Record<string, any> {
  const templates = resultData.templates;
  for (const temKey in templates) {
    if (Object.prototype.hasOwnProperty.call(templates, temKey)) {
      // 解析templates里配置参数的template 拼回原对象
      let values = {};
      if (templates[temKey].template) {
        try {
          values = JSON.parse(templates[temKey].template);
        } catch (err) {
          // TODO 记得上报异常
          values = {};
        }
      } else {
        values = {};
      }
      const bucketNo = templates[temKey].bucketNo;
      templates[temKey] = { bucketNo, ...values, requestId: resultData.requestId };
    }
  }
  return templates;
}

export function shuntFetch(commonHeader: CommonHeader): AxiosInstance {
  return baseFetch({
    baseURL: getEnvConfigrationByDomain().GATEWAY_BASEURL,
    withCredentials: true,
  }, commonHeader, (res: Record<string, any>) => {
    if (res) {
      const handleData = handleShuntAllAbtestData(res);
      return handleData;
    }
    return res;
  });
}

export function shuntDefaultFetch(commonHeader: CommonHeader): AxiosInstance {
  return baseFetch({
    baseURL: getEnvConfigrationByDomain().GATEWAY_BASEURL,
    withCredentials: true,
  }, commonHeader, (res: Record<string, any>) => {
    if (res) {
      const handleData = handleShuntAllAbtestData(res);
      return handleData;
    }
    return res;
  });
}
