/*
 * @Author: shixiaoxia
 * @Date: 2024-09-04 17:49:33
 * @LastEditors: shixiaoxia
 * @LastEditTime: 2024-12-30 10:52:36
 * @Description: 请填写简介
 */
import { CommonHeader, CommonApiParams, CommonAbtestParams } from 'fe-web-common';
import { getSessionID } from 'ybcommon/ybutils/common/sessionIdUtil';
import { getSource } from 'ybcommon/ybutils/common/common';
import { getAppCode, getBusinessOrigin } from 'ybcommon/ybutils/common/company';
import { getMediaUuid } from 'ybcommon/ybmodel/tgUtil';

interface HeaderParam {
  /** 用户id */
  userId: string;
  /** uuid */
  uuid: string;
  /** 用户token */
  userToken: string;
}

/**
 * 获取统一请求头额外参数
 * @param headerParam header通用参数
 * @returns 返回请求头额外参数
 */
export const getCommonHeader = (headerParam: HeaderParam): CommonHeader => {
  const { userId, uuid, userToken } = headerParam;
  const grayFlag = window.__GRAY_FLAG__;
  const grayVersion = window.__GRAY_VERSION__;

  let headers: CommonHeader = {
    authorizationv2: uuid || '', // 切换用户中心标识
    'auth-token': userToken || '',
    'auth-userid': userId || '',
    'auth-uuid': uuid || '',
  };
  if (grayFlag != null && grayFlag !== 'null') { // 灰度标识
    headers = {
      ...headers,
      'x-yb-gray-flag': grayFlag,
      'x-yb-gray-release-time': grayVersion,
      'x-yb-gray-flag-release-time': `${grayFlag}_${grayVersion}`,
    };
  }
  return headers;
};

interface ApiParam {
   /** uuid */
   uuid: string;
   /** 是否小程序内 */
   isWeChatMiniApp: number;
   /** unionId */
   unionId: string;
   /** appId */
   appId: string;
   /** 渠道号 */
   channel: string;
   /** 域名，某些接口会传单独的域名参数 */
   domain?: string;
   /** 当前完整路径，部分接口会传 */
   curPath?: string;
}

/**
 * 获取公共参数
 * @param apiParam 统一的
 * @returns 返回公参
 */
export const getCommonApiParams = (apiParam: ApiParam): CommonApiParams => {
  const { uuid, isWeChatMiniApp, unionId, appId, channel, domain, curPath } = apiParam;
  const wechatMinAppCode = isWeChatMiniApp ? +isWeChatMiniApp : 0;
  const companyCode = getBusinessOrigin();
  const appCode = getAppCode(wechatMinAppCode);
  const source = getSource();
  const busiTraceId = getSessionID();
  const commonApiParams: CommonApiParams = {
    uuid,
    channel,
    domain: domain ? window.location.host : domain,
    busiTraceId,
    tuiguangId: getMediaUuid(),
    companyCode,
    appCode,
    source,
    url_pathname: window.__pathname || window.location.pathname,
    shuntId: window.ybParams?.shuntId,
    appVersion: __VERSION__,
  };
  if (curPath) {
    commonApiParams.curPath = curPath;
  }
  if (+isWeChatMiniApp > 0) {
    commonApiParams.isWeChatMiniApp = +isWeChatMiniApp;
    commonApiParams.appId = appId;
    commonApiParams.unionId = unionId;
  }
  return commonApiParams;
};

interface AbtestParam {
  /** uuid */
  uuid: string;
  /** 是否小程序内 */
  isWeChatMiniApp: number;
  /** 渠道号 */
  channel: string;
  /** 加密userid，不必填 */
  userIdencrypt?: string;
}

/**
 * 获取ab测公参
 * @param abtestParam ab测参数
 * @returns 返回ab测公参
 */
export const getCommonAbtestParams = (abtestParam: AbtestParam): CommonAbtestParams => {
  const { uuid, isWeChatMiniApp, channel, userIdencrypt = '' } = abtestParam;
  const wechatMinAppCode = isWeChatMiniApp ? +isWeChatMiniApp : 0;
  const source = getSource();
  const companyCode = getBusinessOrigin();
  const appCode = getAppCode(wechatMinAppCode);
  const commonAbTestParams: CommonAbtestParams = {
    source,
    host: window.location.host,
    url_pathname: window.__pathname || window.location.pathname,
    channel,
    userIdencrypt,
    uuid,
    companyCode,
    appCode,
  };
  return commonAbTestParams;
};

export type SignleCommonParams = HeaderParam & ApiParam & AbtestParam;
