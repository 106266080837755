// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".planBox_-l\\+DVlDE {\n  width: 100%;\n  display: flex;\n  overflow-y: hidden;\n  overflow-x: auto;\n}\n.planBox_-l\\+DVlDE .planItem_SiNZfJk0 {\n  flex-shrink: 0;\n  margin-right: 0.13333rem;\n  font-family: PingFangSC-Regular;\n  font-weight: 400;\n  font-size: 0.4rem;\n  color: #333333;\n  box-sizing: border-box;\n  background: #F7F7F7;\n  border-radius: 0.13333rem;\n  border: 0.5px solid #F7F7F7;\n  text-align: center;\n  position: relative;\n}\n.planBox_-l\\+DVlDE .planItem_SiNZfJk0 .planName_HvCbzYP8 {\n  width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  box-sizing: border-box;\n  padding: 0.26667rem 0;\n}\n.planBox_-l\\+DVlDE .planItem_SiNZfJk0 .feature_g-RsVGAw {\n  width: 100%;\n  font-size: 0.32rem;\n  padding-bottom: 0.26667rem;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  margin-top: -0.10667rem;\n}\n.planBox_-l\\+DVlDE .planItem_SiNZfJk0:last-child {\n  margin-right: 0;\n}\n.planBox_-l\\+DVlDE .planItemOn_0m1TJ0Of {\n  font-family: PingFangSC-Medium;\n  font-weight: 500;\n  color: #FF6100;\n  border-color: #FFD2B6;\n  background-color: #FFF3E7;\n}\n.planBox_-l\\+DVlDE .planItemOn_0m1TJ0Of .planItem_SiNZfJk0 {\n  font-family: PingFangSC-Medium;\n  font-weight: 500;\n  color: #FF6100;\n}\n.planBox_-l\\+DVlDE .planItemOn_0m1TJ0Of .feature_g-RsVGAw {\n  font-family: PingFangSC-Medium;\n  font-weight: 500;\n  color: #FF6100;\n}\n.planBox_-l\\+DVlDE .itemFlex_BMjvD7Nz {\n  flex: 1;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"planBox": "planBox_-l+DVlDE",
	"planItem": "planItem_SiNZfJk0",
	"planName": "planName_HvCbzYP8",
	"feature": "feature_g-RsVGAw",
	"planItemOn": "planItemOn_0m1TJ0Of",
	"itemFlex": "itemFlex_BMjvD7Nz"
};
export default ___CSS_LOADER_EXPORT___;
