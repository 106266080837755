/* eslint-disable no-use-before-define */
/**
 * 协议二级菜单，主要用于折叠版(优化)的协议，也可酌情用于其他场景
 */
import { FC, forwardRef, useEffect, useRef, useState } from 'react';
import { Icon } from 'antd-mobile-v2';
import styles from './index.less';

interface ISubListItem {
  termsTitle: string;
  registerNo: string;
  protocolId: string;
}

interface IListItem {
  key: string;
  productName?: string;
  type: number;
  value: string;
  contractTerms?: ISubListItem[] | null;
}

interface ISubMenuProps {
  data: IListItem;
  clickItemHandler: () => void; // 点击二级菜单Item事件
  extendsClassName: string;
}

interface ISubMenuListProps {
  subList: ISubListItem[];
  onItemClick: (MouseEvent, _IListItem) => void;
  extendsClassName: string;
}

interface ICombineSubMenuListProps {
  subList: IListItem[];
  onItemClick: (MouseEvent, _IListItem) => void;
  extendsClassName: string;
}

const SubMenuList: FC<ISubMenuListProps> = ({ subList = [], onItemClick = () => { }, extendsClassName = '' }): JSX.Element => (
  <ul className={`${styles.subList} cus-sub-list ${extendsClassName}`}>
    {
      subList.map((item, index) => (
        <li className={`${styles.subListItem} cus-sub-list-item`} onClick={(e) => { onItemClick(e, item); }} key={`${item.registerNo}-${index + 1}`}>
          <div className={`${styles.text} cus-sub-text`}>{item.termsTitle}</div>
          <Icon type="right" size="xxs" color="#A8ADB1" className="cus-sub-point" />
        </li>
      ))
    }
  </ul>
);

const SubMenu: FC<ISubMenuProps> = (props): JSX.Element => {
  const { data: { key, contractTerms } = {}, clickItemHandler, extendsClassName = '' } = props;
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const handleShowSubMenu = () => setShowSubMenu(!showSubMenu);

  return (
    <div className={extendsClassName}>
      <div className={`${styles.header} cus-sub-header`} onClick={handleShowSubMenu}>
        <div className={`${styles.text} cus-sub-text`}>{key}</div>
        <Icon type="right" size="xxs" color="#CCCCCC" className={`${styles.point} cus-sub-point ${showSubMenu ? styles.rotatePoint : ''}`} />
      </div>
      {contractTerms ? <SubMenuList extendsClassName={showSubMenu ? styles.show : styles.hidden} subList={contractTerms} onItemClick={clickItemHandler} /> : null}
    </div>
  );
};

function CombineSubMenu(props) {
  const { data, clickItemHandler, extendsClassName = '', protocalTitle } = props;
  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  const subMenuRef = useRef<HTMLUListElement>(null);
  const handleShowSubMenu = () => setShowSubMenu(!showSubMenu);

  useEffect(() => {
    if (protocalTitle) { // 若传入protocalTitle则将相应的展开其他关闭
      if (data.key === protocalTitle) {
        setShowSubMenu(true);
      } else {
        setShowSubMenu(false);
      }
    }
  }, [protocalTitle]);

  useEffect(() => {
    if (showSubMenu) {
      const containerEle = document.getElementById('protocal-optomize-modal-combine-list');
      /** 协议优化弹窗协议列表容器元素的bottom */
      const containerEleBottom = containerEle?.getBoundingClientRect()?.bottom;
      /** 子菜单元素的bottom */
      const subMenuEleBottom = subMenuRef.current?.getBoundingClientRect()?.bottom;
      if (containerEleBottom && subMenuEleBottom && containerEleBottom < subMenuEleBottom) {
        // 子菜单展开时，如果在视口外，则滚出来
        containerEle.scrollBy(0, subMenuEleBottom - containerEleBottom);
      }
    }
  }, [showSubMenu]);

  return (
    <div id={data.key === protocalTitle ? 'protocal-optomize-modal-combine-active-item' : ''} className={extendsClassName}>
      <div
        className={`cus-sub-header ${styles.combineHeader}`}
        onClick={(e) => {
          // console.log('data===', data);
          if (data?.list?.length) {
            handleShowSubMenu();
          } else {
            clickItemHandler(e, data);
          }
        }}
      >
        <div className={`cus-sub-text ${styles.combineText}`}>{data.key}</div>
        {data.list ? <Icon type="right" size="xxs" color="#CCCCCC" className={`${styles.point} cus-sub-point ${showSubMenu ? styles.rotatePoint : ''}`} /> : null}
      </div>
      {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        data.list ? <CombineSubMenuList ref={subMenuRef} extendsClassName={showSubMenu ? styles.show : styles.hidden} subList={data.list} onItemClick={clickItemHandler} /> : null
      }
    </div>
  );
}

const CombineSubMenuList = forwardRef<HTMLUListElement, ICombineSubMenuListProps>((props, ref) => {
  const { extendsClassName, subList, onItemClick } = props;
  // const [showSubMenu, setShowSubMenu] = useState<boolean>(false);
  // const handleShowSubMenu = () => setShowSubMenu(!showSubMenu);

  return (
    <ul ref={ref} className={`${styles.subList} cus-sub-list ${extendsClassName}`}>
      {
        subList.map((item, index) => (
          <CombineSubMenu key={`${item.key}-${index + 1}`} data={item} clickItemHandler={onItemClick} extendsClassName={extendsClassName} />
        ))
      }
    </ul>
  );
});

export {
  CombineSubMenu,
  SubMenu,
  SubMenuList, // 暴露二级子菜单组件，可用于后续自定义一级菜单时使用
};
