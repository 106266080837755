import { UCFetch } from 'ybcommon/ybfetch';
import { isShouxin } from 'ybcommon/ybutils/common/company';
import { openHrefUrl } from 'store/UrlTools';
import { addUrlParams, addNewUserInfoToUrl } from 'ybcommon/ybutils/common/common';
import { getMediaUuid } from 'ybcommon/ybmodel/tgUtil';
import { getSessionID } from 'ybcommon/ybutils/common/sessionIdUtil';
import userStore from '../../store/UserStore';
/**
   * 跨域同步
   * @param {*} redirectUrl 重定向地址
   * @param {*} isrep 是否替换当前链接
   * @param {*} needSxCross 首信是否需要处理跨域
   */
export const crossRegion = (redirectUrl, isrep = true, needSxCross = false) => {
  let tempUrl = redirectUrl.split('#')[0];
  const { host } = window.location;
  if (!userStore.hasLogin || tempUrl.indexOf(host) !== -1 || (isShouxin() && !needSxCross)) {
    openHrefUrl(redirectUrl, isrep, true, false);
    return;
  }
  const tempMediaUuid = getMediaUuid();
  tempUrl = addUrlParams(tempUrl, { mediaUuid: tempMediaUuid, busiTraceId: getSessionID() });
  tempUrl = addNewUserInfoToUrl(tempUrl);

  // 跨域
  UCFetch.post('/api/cross/encrypt')
    .then((res) => {
      tempUrl = addUrlParams(tempUrl, { encryData: res });
      openHrefUrl(tempUrl, isrep, true, false);
    })
    .catch((err) => {
      console.log(err);
    });
};
